const WidthHeightCSS = () => (
    <>
        <ul>
            <li>
                Šírka a výška 
                <ul><li><b>width: 100px; height: 100px;</b></li></ul>
                
            </li>
            <li>
                Maximálna šírka a výška 
                <ul><li><b>max-width: 100px; max-height: 100px;</b></li></ul>
            </li>
            <li>
                Minimálna šírka a výška
                <ul><li><b>min-width: 100px; min-height: 100px;</b></li></ul>
            </li>
        </ul>
    </>
)

export default WidthHeightCSS;