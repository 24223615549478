import ContainerImg from "./assets/container.png";

const ContainerCSS = () => (
    <>
        <ul>
            <li>
                Obmedzuje šírku stránky
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.container {
    max-width: 990px;
    margin: auto;
    padding: 20px;
}
`}               
                    </code>
                </pre>            
            </div>
            <div className="col">
                <img src={ContainerImg} alt="Container" />
            </div>
        </div>
    </>
)

export default ContainerCSS;