import WebpSupport from "./assets/webp-support.png";

const ImageOptimise = () => (
    <>
        <ul>
            <li>Rozmer obrázkov
                <ul>
                    <li>Zmenšite obrázky podľa obsahu</li>
                    <li>Používajte thumbnail-y</li>
                </ul>
            </li>
            <li>
                Veľkosť obrázkov
                <ul>
                    <li>Minifikujte obrázky</li>
                    <li><a href="https://tinyjpg.com/" target="_blank" rel="noreferrer">TinyJPG</a> zmenší veľkosť až o 70%</li>
                </ul>
            </li>
            <li>
                Využívajte formát WEBP
                <ul>
                    <li>Menšia veľkosť ako PNG a JPG</li>
                    <li>Nie je podporovaný niektorými prehliadačmi</li>
                </ul>
            </li>
        </ul>
    </>
);

const ImageOptimise2 = () => (
    <>
        <ul>
            <li>Prehliadače (ne)podporujúce webp formát</li>
        </ul>
        <img src={WebpSupport} alt="Webp support" style={{width: 600}} />
    </>
);

const ImageOptimise3 = () => (
    <>
        <ul>
            <li>Ak si chceme byť istí</li>
        </ul>
        <pre>
            <code>
{`<picture>
  <source srcset="img/obrazok.webp" 
    type="image/webp" />
  <source srcset="img/obrazok.jpg" 
    type="image/jpeg" /> 
  <img src="img/obrazok.jpg" 
    alt="Nazov obrazka"/>
</picture>
`}
            </code>
        </pre>
    </>
);

export {ImageOptimise, ImageOptimise2, ImageOptimise3};