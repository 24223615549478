import GoogleImage from "./assets/google-pagespeed.png";

const Analyse = () => (
    <>
        <img src={GoogleImage} alt="Google PageSpeed Insight" style={{width: 300}} />
        <ul>
            <li><a href="https://developers.google.com/speed/pagespeed/insights/?hl=sk" target="_blank" rel="noreferrer">
                Google PageSpeed Insights</a>
                <ul>
                    <li>Kontrola veľkosti súborov</li>
                    <li>Kontrola priebehu načítania stránky</li>
                    <li>Kontrola rýchlosti</li>
                    <li>Percentuálne vyhodnotenie</li>
                </ul>
            </li>
            <li>Pozor! Výsledky môžu mať odchýlku</li>
        </ul>
    </>
);

export default Analyse;