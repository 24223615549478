import "./assets/example1.css";

const TransitionCSS = () => (
    <>
        <ul>
            <li>
                <b>transition</b> - Prechod
                <ul>
                    <li>
                        Animuje len zmeny vlastností
                    </li>
                    <li>
                        Na rozdiel od animácie nie je spustený samovoľne
                    </li>
                </ul>
            </li>
        </ul>
        <table style={{width: "100%", fontSize: "0.65em", marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>* transition-property</td>
                    <td>Parameter (width, color, all, ...)</td>
                </tr>
                <tr>
                    <td>* transition-duration</td>
                    <td>Čas trvania prechodu</td>
                </tr>
                <tr>
                    <td>transition-timing-function</td>
                    <td>Funkcia plynutia času (ease, linear, ...)</td>
                </tr>
                <tr>
                    <td>transition-delay</td>
                    <td>Zdržanie pred prechodom</td>
                </tr>
            </tbody>
        </table>
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`transition: background 2s linear 1s;
`}               
            </code>
        </pre>  
    </>
);

const TransitionCSS2 = () => (
    <>
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`.t-element {
  background-color: red;
  transition: background-color 1s linear 200ms;
}
.t-element:hover {
    background-color: blue;
}
`}               
            </code>
        </pre>  
        <div className="t-element" />
    </>
);

export {TransitionCSS, TransitionCSS2};