import FavImage from "./assets/favicon-ex.png";

const Favicon = () => (
    <>
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`<head>
  ...
  <link rel="icon" type="image/x-icon" href="/cesta/favicon.ico" >
  <link rel="icon" type="image/png" href="/cesta/favicon.png" >
  <link rel="icon" type="image/gif" href="/cesta/favicon.gif" >	
</head>
`}               
            </code>
        </pre>  

        <img src={FavImage} alt="Favicon example" />

        <a href="https://realfavicongenerator.net/" target="_blank" rel="noreferrer">Favicon generátor</a>
    </>
);

export default Favicon;