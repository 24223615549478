import styled from 'styled-components';
import PCIMage from "../../images/logo-icon.png";

const Title = styled.h1`
    position: absolute;
    z-index: 2;
`

const Content = styled.div`
    padding-top: 8em;
`

const Lector = styled.div`
    margin-top: 4em;
    text-align: left;
    font-size: 1em;
`;

const MainImage = styled.div`
    position: absolute;
    bottom: -.5em;
    right: 0;
    width: 500px;
`

const Intro = () => (
    <>
        <Title>Programovanie <br /><b>webových stránok</b> 1</Title>
        <Content>
            <div className="left"><a href="https://www.wooacademy.sk" style={{color: "initial"}} target="_blank" rel="noreferrer" title="www.wooacademy.sk">WOOACADEMY</a></div>
            <Lector>
                Mgr. <b>Patrik</b> Hudák
            </Lector>
        </Content>
        <MainImage>
            <img src={PCIMage} alt="Computer" />
        </MainImage>
    </>
);

export default Intro;