import styled from "styled-components";
import Logo from "../../images/logo.png";

const LogoArea = styled.div`
    position: absolute;
    right: 0;
    width: 6em;
    margin: 0;
`;

const MainLogo = () => (
    <LogoArea>
        <a href="https://wooacademy.sk/" target="_blank" rel="noreferrer" >
            <img src={Logo} alt="Wooacademy Logo" style={{margin: 0}} />
        </a>
    </LogoArea>
);

export default MainLogo;