import {PropTypes} from "prop-types";
import MainLogo from "../components/MainLogo"

const Slide = (props) => {
    let heading;
    if(props.type === "empty") {
    }
    else {
        if(props.overtitle) {
            heading = (
                <>
                    <div className="subtitle">{props.overtitle}</div>
                    <h2>{props.title}</h2>
                </>
            );
        }
        else {
            heading = <h2>{props.title}</h2>;
        }
    }

    return (
        <section data-visibility={ props.uncountered ? "uncounted" : "" }>
            <MainLogo />
            {heading}
            {heading ? <hr className="h-hr" /> : null}
            {props.children}
        </section>
    )
};

Slide.propTypes = {
    children: PropTypes.node.isRequired,
    uncountered: PropTypes.bool,
    type: PropTypes.string,
    title: PropTypes.string,
    overtitle: PropTypes.string,
}

export default Slide;



