import { useState } from "react";
import styled from "styled-components";
import {ImCross} from "react-icons/im";
import {FaCheck} from "react-icons/fa";

const Input = styled.input`
    width: 70px;
    text-align: center;
    font-size: 1em;
    padding: 0, 10px;
    margin: 0 20px;
`

const ExCssChars = () => {

    const [values, setValues] = useState({
        leftPar: "", rightPar: "", leftPar2: "", rightPar2: "", semi: "", hash: "", at: "", colon: ""
    });

    function inputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setValues({
            ...values,
            [name]: value
        });
    }

    const success = <span className="success"><FaCheck /></span>;
    const error = <span className="danger"><ImCross /></span>;

    return (
        <>
            <div className="left">
                Opíšte uvedené znaky do polí.
            </div>
            <div className="row" style={{marginTop: "1em"}}>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>{"("}</label>
                        <Input type="text" name="leftPar" value={values.leftPar} onChange={inputChange} />
                        {values.leftPar === "" ? "" : (values.leftPar === "(" ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>{")"}</label>
                        <Input type="text" name="rightPar" value={values.rightPar} onChange={inputChange} />
                        {values.rightPar === "" ? "" : (values.rightPar === ")" ? success : error)}
                    </div>
                </div>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>{"{"}</label>
                        <Input type="text" name="leftPar2" value={values.leftPar2} onChange={inputChange} />
                        {values.leftPar2 === "" ? "" : (values.leftPar2 === '{' ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>{"}"}</label>
                        <Input type="text" name="rightPar2" value={values.rightPar2} onChange={inputChange} />
                        {values.rightPar2 === "" ? "" : (values.rightPar2 === '}' ? success : error)}
                    </div>
                </div>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>;</label>
                        <Input type="text" name="semi" value={values.semi} onChange={inputChange} />
                        {values.semi === "" ? "" : (values.semi === ';' ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>:</label>
                        <Input type="text" name="colon" value={values.colon} onChange={inputChange} />
                        {values.colon === "" ? "" : (values.colon === ':' ? success : error)}
                    </div>
                </div>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>@</label>
                        <Input type="text" name="at" value={values.at} onChange={inputChange} />
                        {values.at === "" ? "" : (values.at === '@' ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>#</label>
                        <Input type="text" name="hash" value={values.hash} onChange={inputChange} />
                        {values.hash === "" ? "" : (values.hash === '#' ? success : error)}
                    </div>
                </div>
            </div>
        </>
    )
};

export default ExCssChars;