import "./assets/example1.css";

const AnimationCSS = () => (
    <>
        <ul>
            <li><b>@keyframes</b> - Postup v animácii</li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`@keyframes nazovAnimacie {
  0% {
    left: 0px;
  }
  100% {
    left: 300px;
  }
}
`}               
                    </code>
                </pre>  
            </div>
            <div className="col">
                
            </div>
        </div>
    </>
);

const AnimationCSS2 = () => (
    <>
        <ul>
            <li><b>animation</b> - Parametre animácie</li>
        </ul>
        <table style={{width: "100%", fontSize: "0.65em"}}>
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>* animation-name</td>
                    <td>Názov keyframe-u</td>
                </tr>
                <tr>
                    <td>* animation-duration</td>
                    <td>Čas trvania animácie</td>
                </tr>
                <tr>
                    <td>animation-timing-function</td>
                    <td>Funkcia plynutia času (ease, linear, ...)</td>
                </tr>
                <tr>
                    <td>animation-delay</td>
                    <td>Zdržanie pred animáciou</td>
                </tr>
                <tr>
                    <td>animation-iteration-count</td>
                    <td>Počet cyklov alebo infinite (nekonečno)</td>
                </tr>
                <tr>
                    <td>animation-direction</td>
                    <td>Smer animácie (normal, reverse)</td>
                </tr>
            </tbody>
        </table>
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`animation: nazovAnimacie 5s infinite;
`}               
            </code>
        </pre>  
    </>
);

const AnimationCSS3 = () => (
    <>
        <div className="row">
            <div className="col">
                <pre style={{fontSize: ".65em", marginBottom: 0}}>
                    <code>
{`.moj-element {
  animation: nazovAnimacie 5s infinite;
}
@keyframes nazovAnimacie {
  0% {
    left: 0px;
  }
  50% {
    left: 250px;
  }
  100% {
    left: 0px;
  }
}
`}               
                    </code>
                </pre>  
            </div>
            <div className="col">
                <div style={{position: "relative"}}>
                    <div className="moj-element" style={{backgroundColor: "red"}} />
                </div>
            </div>
        </div>
    </>
);

export {AnimationCSS, AnimationCSS2, AnimationCSS3};