import "./assets/example1.css";

const CascadesCSS = () => (
    <>
        <ul>
            <li>Štýlovanie vnorených selektorov</li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.box {
  background: silver;
}
.box b {
  color: red;
}
.box b u {
  color: blue;
}
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <pre style={{display: "grid", height: 250}}>
                    <code>
{`<div class="box">
  <b>A</b> 
  <b>B</b> 
  <b><u>C</u></b> 
  D E F 
</div>
`}               
                    </code>
                </pre>

                <div className="box">
                    <b>A</b> <b>B</b> <b><u>C</u></b> D E F 
                </div>
            </div>
        </div>
    </>
);

const CascadesCSS2 = () => (
    <>
        <ul>
            <li>Štýlovanie kombinácií selektorov</li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.box {
  background: silver;
}
.box.cerveny {
  background: red;
}
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <pre style={{display: "grid", height: 250}}>
                    <code>
{`<div class="box">
  - BOX A -
</div>
<div class="box cerveny">
  - BOX B -
</div>
`}               
                    </code>
                </pre>

                <div className="box">
                    - BOX A -
                </div>
                <div className="box cerveny">
                    - BOX B -
                </div>
            </div>
        </div>
    </>
);

const CascadesCSS3 = () => (
    <>
        <ul>
            <li>Spojme všetko dohromady</li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.box {
  background: silver;
}
.box.cerveny {
  background: red;
}
.box.cerveny i {
  color: blue
}
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <pre style={{display: "grid", height: 250}}>
                    <code>
{`<div class="box">
  BOX A - <i>ITALIC</i>
</div>
<div class="box cerveny">
  BOX B - <i>ITALIC</i>
</div>
`}               
                    </code>
                </pre>

                <div className="box">
                    BOX A - <i>ITALIC</i>
                </div>
                <div className="box cerveny">
                    BOX B - <i>ITALIC</i>
                </div>
            </div>
        </div>
    </>
);

const CascadesCSS4 = () => (
    <>
        <ul>
            <li>Jeden štýl pre viac prvkov</li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.box1, .box2 {
  color: red;
}
`}               
                    </code>
                </pre>
                <div className="box1" style={{backgroundColor: "aqua"}}>
                    BOX A - <i>ITALIC</i>
                </div>
                <div className="box2" style={{backgroundColor: "silver"}}>
                    BOX B - <i>ITALIC</i>
                </div>
            </div>
            <div className="col">
                <pre style={{display: "grid"}}>
                    <code>
{`<div class="box">
  BOX A - <i>ITALIC</i>
</div>
<div class="krabica">
  BOX B - <i>ITALIC</i>
</div>
`}               
                    </code>
                </pre>
            </div>
        </div>
    </>
);

const CascadesCSS5 = () => (
    <>
        <ul>
            <li>Prepisovanie štýlov</li>
            <ul>
                <li>Čím nižšie, tým prioritnejšie</li>
                <li><b>!important</b> v prípade núdze</li>
            </ul>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.box {
  background: silver;
}
.box {
  background: red;
}
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <pre style={{display: "grid", height: 150}}>
                    <code>
{`<div class="box">
  - BOX A -
</div>
`}               
                    </code>
                </pre>

                <div className="box" style={{background: "red"}}>
                    - BOX A -
                </div>
            </div>
        </div>
    </>
);

const CascadesCSS6 = () => (
    <>
        <pre>
            <code>
{`.box {
  background: silver !important;
}
.box {
  background: red;
}
`}               
            </code>
        </pre>


        <div className="row">
            <div className="col">
                <pre style={{display: "grid", height: 120}}>
                    <code>
{`<div class="box">
  - BOX A -
</div>
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <div className="box">
                    - BOX A -
                </div>
            </div>
        </div>
        <a style={{fontSize: ".8em"}} href="https://www.w3schools.com/cssref/css_selectors.asp" rel="noreferrer" target="_blank">Kompletná tabuľka selektorov a možností</a>
    </>
);

export {CascadesCSS, CascadesCSS2, CascadesCSS3, CascadesCSS4, CascadesCSS5, CascadesCSS6};