import "./assets/example1.css";
import ResponsiveImg from "./assets/responsive.png";

const ResponsivityCSS = () => (
    <>
        <pre>
            <code>
{`.moj-element {
  width: 400px;
}
@media only screen and (max-width: 600px) {
  .moj-element {
    width: 100%;
  }
}
`}               
            </code>
        </pre>
        <div className="moj-r-element" />
    </>
);

const ResponsivityCSS2 = () => (
  <>
    <img src={ResponsiveImg} alt="Responsive Table" />
  </>
)

export {ResponsivityCSS, ResponsivityCSS2};