import WebImage from "./assets/website-prev.jpg";
import WebImage2 from "./assets/website-prev2.png";

const HtmlStructure = () => (
    <>
    <div className="row">
        <div className="col">
            <table style={{border: "2px solid"}}>
                <tbody>
                    <tr>
                        <td colSpan="2" style={{border: "2px solid"}}>{`<nav>`}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{border: "2px solid"}}>{`<header>`}</td>
                    </tr>
                    <tr>
                        <td>
                            {`<main>`}
                            <table>
                                <tbody>
                                    <tr>
                                        <td  style={{border: "2px solid"}}>
                                            {`<section>`}
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontSize: ".8em"}}>{`<article>`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontSize: ".8em"}}>{`<article>`}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style={{border: "2px solid"}} >{`<aside>`}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">{`<footer>`}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="col">
            <div className="r-stack">
                <img src={WebImage} className="fragment current-visible" alt="Website preview" style={{marginTop: 0}} />
                <img src={WebImage2} className="fragment" alt="Website preview" style={{marginTop: 0}} />
            </div>
            
        </div>
    </div>
        
    </>
);

const HtmlStructure2 = () => (
    <>
        <div className="row">
            <div className="col" style={{maxWidth: "40%"}}>
                <table style={{border: "2px solid", fontSize: ".8em"}}>
                    <tbody>
                        <tr>
                            <td colSpan="2" style={{border: "2px solid"}}>{`<nav>`}</td>
                        </tr>
                        <tr>
                            <td colSpan="2" style={{border: "2px solid"}}>{`<header>`}</td>
                        </tr>
                        <tr>
                            <td>
                                {`<main>`}
                                <table>
                                    <tbody>
                                        <tr>
                                            <td  style={{border: "2px solid"}}>
                                                {`<section>`}
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{fontSize: ".8em"}}>{`<article>`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontSize: ".8em"}}>{`<article>`}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{border: "2px solid"}} >{`<aside>`}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">{`<footer>`}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col">
                <pre style={{display: "grid"}}>
                    <code>
{`<html>
  <head>
    <title> </title>
  </head>
  <body>
    <nav> </nav>
    <header> </header>
    <aside> </aside>
    <main>
      <article> </article>
      <section>
        <article> </article>
        <article> </article>
      </section>
    </main>
    <section>
      <article> </article>
      <article> </article>
    </section>
    <footer> </footer>
  </body>
</html>
`}
                    </code>
                </pre>
            </div>
        </div>
       
    </>
);


export {HtmlStructure, HtmlStructure2};