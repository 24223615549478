import {FaRegSquare} from "react-icons/fa";

const CheckList = () => (
    <>
        <div className="left" style={{fontSize: "0.7em", lineHeight: "1.5em"}}>
            <div>
                <FaRegSquare /> V dokumente mám len jeden <b>{"<h1>"} tag</b>
            </div>
            <div>
                <FaRegSquare /> Mám nastavené základné <b>meta údaje</b>
            </div>
            <div>
                <FaRegSquare /> Mám nastavené <b>social OG</b> údaje
            </div>
            <div>
                <FaRegSquare /> Štýlovanie je oddelené a <b>minifikované</b>
            </div>
            <div>
                <FaRegSquare /> Obrázky majú požadujúce <b>rozmery a veľkosť</b>
            </div>
            <div>
                <FaRegSquare /> Súbory mám načítavané pred-načítaním - <b>preload</b>
            </div>
            <div>
                <FaRegSquare /> Stránka má vytvorený favicon
            </div>
            <div>
                <FaRegSquare /> Mám vygenerovaný súbor <b>sitemap.xml</b>
            </div>
            <div>
                <FaRegSquare /> Mám vytvorený súbor <b>robots.txt</b>
            </div>
            <div>
                <FaRegSquare /> Stránku mám otestovanú cez <b>Facebook Share Debugger</b>
            </div>
            <div>
                <FaRegSquare /> Stránku mám otestovanú cez <b>Google PageSpeed Insight</b>
            </div>
            <div>
                <FaRegSquare /> Stránku mám optimalizovanú <b>pre tlač</b>
            </div>
        </div>
    </>
);

export default CheckList;