import {useState} from "react";
import {BsArrowUpDown, BsArrowLeftRight, BsArrowsMove,BsBoxArrowInRight, BsBoxArrowInUp, BsBoxArrowInLeft, BsBoxArrowInDown} from "react-icons/bs";

const MarginCSS = () => {

    const [_margin, setMargin] = useState(50);
    const [_padding, setPadding] = useState(50);

    return (
        <>
            <ul>
                <li>
                    Margin - vonkajší okraj
                </li>
                <li>
                    Padding - vnútorný okraj
                </li>
            </ul>
            <div className="row">
                <div className="col">
                    <pre>
                        <code>
{`.cerveny-box {
    margin: ${_margin}px;
    padding: ${_padding}px;
}
`}               
                        </code>
                    </pre>

                    <input type="range" min="0" max="100" value={_margin} onChange={(e)=>setMargin(parseInt(e.target.value))} className="slider" id="margin" />
                    <div className="left" style={{fontSize: "0.8em"}}>Margin: <b>{_margin}</b>px</div>

                    <input type="range" min="0" max="100" value={_padding} onChange={(e)=>setPadding(parseInt(e.target.value))} className="slider" id="padding" style={{marginTop: "1em"}} />
                    <div className="left" style={{fontSize: "0.8em"}}>Padding: <b>{_padding}</b>px</div>

                </div>
                <div className="col">
                    <br />
                    <div style={{background: "silver", width: "max-content", alignSelf: "center"}}>
                        <div style={{margin: _margin, background: "red", width: 150,height: 150, padding: _padding, textAlign: "left"}}>
                            <div style={{width: 80, height: 80, background: "blue"}} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const MarginCSS2 = () => (
    <>
        <ul>
            <li>Vzdialenosti okrajov</li>
        </ul>
        <table style={{width: "100%", fontSize: "0.8em"}}>
            <tbody>
                <tr>
                    <td>
                        <BsArrowsMove /> 10px
                    </td>
                    <td>
                        margin: 10px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsArrowUpDown /> 10px, <BsArrowLeftRight /> 20px
                    </td>
                    <td>
                        margin: 10px 20px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsBoxArrowInDown /> 10px, <BsBoxArrowInLeft /> 15px, <BsBoxArrowInUp /> 20px, <BsBoxArrowInRight /> 25px
                    </td>
                    <td>
                        margin: 10px 15px 20px 25px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsBoxArrowInDown /> 10px
                    </td>
                    <td>
                        margin-top: 10px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsBoxArrowInLeft /> 10px
                    </td>
                    <td>
                        margin-right: 10px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsBoxArrowInUp /> 10px
                    </td>
                    <td>
                        margin-bottom: 10px;
                    </td>
                </tr>
                <tr>
                    <td>
                        <BsBoxArrowInRight /> 10px
                    </td>
                    <td>
                        margin-left: 10px;
                    </td>
                </tr>
            </tbody>
        </table>
        
    </>
);

export {MarginCSS, MarginCSS2};