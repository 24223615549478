const SelectorCSS = () => (
    <>
        <ul>
            <li>
                Selektor <b>tagu</b>
                <ul>
                    <li>Všetky tagy <b>img</b> budú zdieľať tento štýl</li>
                </ul>
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>CSS</b></div>
                <pre>
                    <code>
            
{`img { 
    width: 100px ;
}
`}
                      </code>
                </pre>
            </div>
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>HTML</b></div>
                <pre>
                    <code>
{`<img 
    id="obrazok-4" 
    class="trieda-obrazok"
/>
`}               
                    </code>
                </pre>
            </div>
        </div>
    </>
);

const SelectorCSS2 = () => (
    <>
        <ul>
            <li>
                Selektor <b>triedy</b> - class
                <ul>
                    <li>Všetky rovnaké <b>triedy</b> budú zdieľať tento štýl</li>
                </ul>
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>CSS</b></div>
                <pre>
                    <code>
            
{`.trieda-obrazok { 
    width: 100px ;
}
`}
                      </code>
                </pre>
            </div>
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>HTML</b></div>
                <pre>
                    <code>
{`<img 
    id="obrazok-4" 
    class="trieda-obrazok"
/>
`}               
                    </code>
                </pre>
            </div>
        </div>
    </>
);

const SelectorCSS3 = () => (
    <>
        <ul>
            <li>
                Selektor <b>identifikátora</b> - id
                <ul>
                    <li>Len prvý element s týmto <b>id</b> bude mať tento štýl</li>
                </ul>
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>CSS</b></div>
                <pre>
                    <code>
            
{`#obrazok-4 { 
    width: 100px ;
}
`}
                      </code>
                </pre>
            </div>
            <div className="col">
                <div className="left" style={{marginTop: "1em"}}><b>HTML</b></div>
                <pre>
                    <code>
{`<img 
    id="obrazok-4" 
    class="trieda-obrazok" 
/>
`}               
                    </code>
                </pre>
            </div>
        </div>
    </>
);

const SelectorCSS4 = () => (
    <>
        <ul>
            <li>
                Priame štýlovanie (tzv. inline style)
                <ul>
                    <li>Štýl je aplikovaný len na daný element</li>
                    <li>Môže byť penalizované</li>
                </ul>
            </li>
        </ul>
        <pre>
            <code>
{`<img 
    style="width: 100px;"
    id="obrazok-4" 
    class="trieda-obrazok"
/>
`}               
            </code>
        </pre>
    </>
);

export {SelectorCSS, SelectorCSS2, SelectorCSS3, SelectorCSS4}