import {useEffect} from "react";
import Reveal from 'reveal.js';
import Navigation from "../components/Navigation";
import PresentationConfig from "../presenation-config";
import Slide from "./Slide";
import structure from "./structure";

const Presenation = () => {

    useEffect(() => {
        const reveal = new Reveal(PresentationConfig);
        reveal.initialize();
    }, []);

    const slideSchema = [];
    const content = [];
    let slideCounter = 0;
    for(let i = 0; i < structure.length; i++) {
        slideCounter++;
        let title = `${i}. ${structure[i].title}`;
        if(i === 0 || i === structure.length - 1) {
            title = `${structure[i].title}`;
        }
        content.push(
            <Slide 
                type={structure[i].type ?? null} 
                title={title} 
                key={title}
                uncountered={i === 0}
            >
                {structure[i].content}
            </Slide>
        );
        const sub = structure[i].sub ?? [];
        const subnav = [];
        let secondarySliderCounter = slideCounter - 1;
        if(sub && sub.length > 0) {
            for(let j = 0; j < sub.length; j++) {
                secondarySliderCounter++;
                let subtitle = `${i}.${j + 1}. ${sub[j].title}`;
                if(i === 0 || i === structure.length - 1) {
                    subtitle = `${sub[j].title}`;
                }
                content.push(
                    <Slide 
                        type={sub[j].type ?? null} 
                        title={subtitle} 
                        overtitle={title}
                        key={subtitle}
                    >
                        {sub[j].content}
                    </Slide>
                );
                const partial = sub[j].sub ?? [];
                let therciarySliderCounter = secondarySliderCounter - 1;
                if(partial && partial.length > 0) {
                    for(let k = 0; k < partial.length; k++) {
                        therciarySliderCounter++;
                        content.push(
                            <Slide 
                                type={sub[j].type ?? null} 
                                title={subtitle} 
                                overtitle={title}
                                key={subtitle + "//" + k}
                            >
                                {partial[k]}
                            </Slide>
                        );
                    }
                }
                subnav.push({
                    title: subtitle,
                    slide: secondarySliderCounter,
                });
                secondarySliderCounter = therciarySliderCounter + 1;
            }
        }
        slideSchema.push({
            title: title,
            slide: slideCounter - 1,
            subnav: subnav,
        });
        slideCounter = secondarySliderCounter + 1;
    }

    return (
        <>
            <div className="reveal">
                <div className="slides">
                    {content}
                </div>
                <Navigation items={slideSchema} />
            </div>
        </>
    );
}

export default Presenation;
