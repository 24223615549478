
const WebsiteIntro = () => (
    <>
        <ul>
            <li>
                Vytvoríme <b>nový priečinok</b>
                <ul>
                    <li>Bez diakritiky</li>
                    <li>Bez medzier (namiesto medzery "-")</li>
                </ul>
            </li>
            <li>
                Otvoríme editor kódu (napr. <a href="https://code.visualstudio.com/" rel="noreferrer" target="_blank">VS Code</a>)
            </li>
            <li>
                V priečinku vytvoríme súbor <b>index.html</b>
                <ul>
                    <li>
                        Prostredníctvom editora
                    </li>
                    <li>
                        Prostredníctvom prieskumníka
                    </li>
                    <li>
                        <a href="/files/index.html" download>
                            Stiahnúť súbor index.html
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </>
);


const WebsiteIntro2 = () => (
    <>
        <ul>
            <li>
                Do súboru <b>index.html</b> vložíme kód
            </li>
        </ul>
        <pre style={{fontSize: "0.6em"}}>
            <code data-line-numbers="1|2,12|3,8,9,11|4,5,6,7,10|1-12">
{`<!DOCTYPE html>
<html lang="sk-SK">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" 
      content="width=device-width, initial-scale=1" />
    <title>Moja stránka</title>
  </head>
  <body>
    ...
  </body>
</html>
`}
            </code>
        </pre>
    </>
);

export {WebsiteIntro, WebsiteIntro2};