import BTImage from "./assets/bt-example.png";

const FrameworksCSS = () => (
    <>
        <ul>
            <li>Hotové štýlovacie riešenia</li>
            <li>Najznámejšie framework-y
                <ul>
                    <li>Bootstrap - <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">getbootstrap.com</a></li>
                    <li>Semantic UI - <a href="https://semantic-ui.com/" target="_blank" rel="noreferrer">semantic-ui.com</a></li>
                    <li>MD Bootstrap - <a href="https://mdbootstrap.com/" target="_blank" rel="noreferrer">mdbootstrap.com</a></li>
                </ul>
            </li>
        </ul>
        <img src={BTImage} alt="Bootstrap Example" style={{height: 240}} />
    </>
);

export default FrameworksCSS;