const HTMLTagsUnpair = () => (
    <>
        <div className="left">Nový riadok: <b>{'<br />'}</b></div>
        <div className="left">Obrázok: <b>{'<img />'}</b> <i>(nezabudnite <b>alt</b>)</i></div>
        <pre>
            <code>
{`<img src="/cesta/k/obrazku" alt="Nazov" />
`}
            </code>
        </pre>
        <div className="left">Separátor: <b>{'<hr />'}</b> </div>
        <div className="left">Skrytý komentár:</div>
        <pre>
            <code>
{`<!-- Toto nikto neuvidí --> A toto áno`}
            </code>
        </pre>
    </>
);

export default HTMLTagsUnpair;