import styled from "styled-components";
import ImageHTML from "./assets/css.png";

const Title = styled.div`
    margin-top: 2.2em;
    position: absolute;
    z-index: 2;
`

const Image = styled.div`
    position: absolute;
    z-index: 1;
    top: 5em;
    right: 1em;
    width: 290px;
`

const CSSIntro = (props) => (
    <>
        <Title>
            <h2 style={{fontSize: "2.4em"}}>{props.title}</h2>
            <div className="left">
                <i>Štýlový dokument!</i>
            </div>
        </Title>
        <Image>
            <img src={ImageHTML} alt="CSS Logo" />
        </Image>
    </>
);

export {CSSIntro};