import styled from "styled-components";
import IntroImg from "./assets/intro.gif";
import BAImage from "./assets/ba-img.jpg";

const ImgArea1 = styled.div`
    position: absolute;
    top: 4.5em;
    right: -1em;
    width: 770px;
`

const AboutCSS = () => (
    <>
        <ul>
            <li>Univerzálny štýlovací jazyk</li>
            <li>Rozšírenie HTML</li>
        </ul>
        <ImgArea1>
            <img src={IntroImg} alt="Intro mobile" />
        </ImgArea1>
    </>
);

const AboutCSS2 = () => (
    <>
        <ul>
            <li>Jednoduchý tvorca animácí</li>
            <li>Transofrmuje, tieňuje aj otáča (CSS3)</li>
        </ul>
        <div className="row">
            <div className="col">
                <img src={BAImage} alt="BA 1" />
            </div>
            <div className="col">
                <img src={BAImage} alt="BA 2" style={{filter: "grayscale(100%)"}} />
            </div>
            <div className="col">
                <img src={BAImage} alt="BA 3" style={{boxShadow: "5px 5px 15px 5px #000000"}} />
            </div>
            <div className="col">
                <img src={BAImage} alt="BA 4" style={{transform: "perspective(600px) rotateY(-30deg)"}} />
            </div>
        </div>
    </>
);


export {AboutCSS, AboutCSS2};