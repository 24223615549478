const KeysCSS = () => (
    <>
        <table style={{width: "100%", fontSize: "0.6em"}}>
            <thead>
                <tr>
                    <th>Kľúč</th>
                    <th>Hodnota</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>width</td>
                    <td>veľkosť v ľubovoľnej mierke</td>
                    <td>šírka</td>
                </tr>
                <tr>
                    <td>height</td>
                    <td>veľkosť v ľubovoľnej mierke</td>
                    <td>výška</td>
                </tr>
                <tr>
                    <td>background</td>
                    <td>url('/cesta/obrazka'), farba</td>
                    <td>ľubovoľné pozadie</td>
                </tr>
                <tr>
                    <td>background-color</td>
                    <td>farba</td>
                    <td>farba pozadia</td>
                </tr>
                <tr>
                    <td>color</td>
                    <td>farba</td>
                    <td>farba textu</td>
                </tr>
                <tr>
                    <td>font-size</td>
                    <td>veľkosť v ľubovoľnej mierke</td>
                    <td>veľkosť písma</td>
                </tr>
                <tr>
                    <td>text-align</td>
                    <td>center, right, left, justify</td>
                    <td>zarovnanie písma</td>
                </tr>
                <tr>
                    <td>font-family</td>
                    <td>názov fontu, serif, sans-serif, monospace, ...</td>
                    <td>štýl písma</td>
                </tr>
                <tr>
                    <td>line-height</td>
                    <td>veľkosť v ľubovoľnej mierke</td>
                    <td>riadkovanie</td>
                </tr>
                <tr>
                    <td>text-decoration</td>
                    <td>upravuje alebo ruší štandardnú dekoráciu textu</td>
                    <td>dekorácia textu</td>
                </tr>
            </tbody>
        </table>
    </>
);

export default KeysCSS;