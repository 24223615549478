import "./assets/example1.css";

const SpecialMarksCSS = () => (
    <>
        <table style={{width: "100%", fontSize: "0.8em"}}>
            <tbody>
                <tr>
                    <td>
                        :hover
                    </td>
                    <td>
                        Po ukázaní na objekt kurzorom myši
                    </td>
                </tr>
                <tr>
                    <td>
                        :focus
                    </td>
                    <td>
                        Kliknuté pole formulára
                    </td>
                </tr>
                <tr>
                    <td>
                        :active
                    </td>
                    <td>
                        Aktívny odkaz
                    </td>
                </tr>
                <tr>
                    <td>
                        :link
                    </td>
                    <td>
                        Nenavštívené linky
                    </td>
                </tr>
                <tr>
                    <td>
                        :visited
                    </td>
                    <td>
                        Navštívené linky
                    </td>
                </tr>
                <tr>
                    <td>
                        :nth-child(N)
                    </td>
                    <td>
                        N-tý objekt v poradí
                    </td>
                </tr>
                <tr>
                    <td>
                        :first-child
                    </td>
                    <td>
                        Prvý podradený objekt
                    </td>
                </tr>
                <tr>
                    <td>
                        :not(selektor)
                    </td>
                    <td>
                        Všetko okrem určeného selektora
                    </td>
                </tr>
            </tbody>
        </table>
    </>
);

const SpecialMarksCSS2 = () => (
    <>
        <div className="row">
            <div className="col">
                <pre style={{fontSize: ".65em"}}>
                        <code>
{`.cerveny-box1 {
  background: red;
}
.cerveny-box1:hover {
  background: silver;
}
.cerveny-box1 p {
  color: black;
}
.cerveny-box1 p:nth-child(3) {
  color: blue;
}
`}               
                        </code>
                    </pre>               
            </div>
            <div className="col">
                <div className="cerveny-box1">
                    <p>Text</p><p>Text</p><p>Text</p><p>Text</p><p>Text</p>
                </div>
            </div>
        </div>
    </>
);

export {SpecialMarksCSS, SpecialMarksCSS2};