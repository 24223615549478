const HtmlForm = () => (
    <>
        <div className="row">
            <div className="col">
                <pre>
                    <code data-line-numbers="1-4|5-7|9-18">
{`<form 
  action="/"
  method="get"
>
  <label for="name">
    Meno
  </label>
  <br/>
  <input 
    type="text" 
    name="k_name"
    value="Patrik"
    id="name" 
    placeholder="Meno"
    title="Krstné meno"
    maxlength="6"
    required
  />
</form>
`}
                    </code>
                </pre>
            </div>
            <div className="col">
                <form>
                    <label htmlFor="name">
                        Meno
                    </label><br/>
                    <input 
                        type="text" 
                        name="k_name"
                        maxLength="6"
                        id="name" 
                        defaultValue="Patrik"
                        placeholder="Meno"
                        title="Krstné meno"
                        required
                    />
                </form>
            </div>
        </div>
    </>
);

const HtmlForm2 = () => (
    <>
        <form>
            <div className="row">
                <div className="col">
                    <div className="left">type="<b>text</b>"</div>
                </div>
                <div className="col">
                    <input type="text" defaultValue="Textové pole" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>number</b>"</div>
                </div>
                <div className="col">
                    <input type="number" defaultValue="1234" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>password</b>"</div>
                </div>
                <div className="col">
                    <input type="password" defaultValue="Pa$$word" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>checkbox</b>"</div>
                </div>
                <div className="col">
                    <label>
                        <input type="checkbox" style={{width: 30, height: 30}} defaultChecked /> Súhlasím
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>radio</b>"</div>
                </div>
                <div className="col">
                    <label>
                        <input type="radio" name="gender" style={{width: 30, height: 30}} defaultChecked /> Muž
                    </label>
                    <label>
                        <input type="radio" name="gender" style={{width: 30, height: 30}} /> Žena
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>date</b>"</div>
                </div>
                <div className="col">
                    <input type="date" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="left">type="<b>submit</b>"</div>
                </div>
                <div className="col">
                    <input type="submit" value="Odoslať" />
                </div>
            </div>
        </form>
    </>
);

const HtmlForm3 = () => (
    <>
        <form>
            <div className="row">
                <div className="col">
                    <pre>
                        <code>
{`<textarea name="text"> 
  Text ...
</textarea>
`}
                        </code>
                    </pre>
                </div>
                <div className="col">
                    <textarea placeholder="Napíšte text ..." style={{fontSize: "0.8em", marginTop: "0.7em"}} defaultValue="Text ..." />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <pre style={{fontSize: ".6em", marginTop: 0}}>
                        <code>
{`<select name="digital">
  <option 
   value="Programovanie"
   selected
  >
    Programovanie
  </option>
  <option>...</option>
</select>
`}
                        </code>
                    </pre>
                </div>
                <div className="col">
                    <select style={{fontSize: "0.8em", marginTop: 0}} defaultValue="1">
                        <option value="1">Programovanie</option>
                        <option value="2">Marketing</option>
                        <option value="3">Dizajn</option>
                    </select>
                </div>
            </div>

        </form>
    </>
);

const HtmlForm4 = () => (
    <>
        <div className="row">
            <div className="col">
                <pre style={{fontSize: ".6em"}}>
                    <code>
{`<form 
  action="/" 
  method="post" 
  enctype="multipart/form-data"
>
  <input 
    type="file"
    name="gallery"
    accept="image/png, image/jpeg"
    multiple
  />
</form>
`}
                    </code>
                </pre>
            </div>
            <div className="col">
                <form style={{display: "grid",fontSize: ".6em", marginTop: "1em"}}>
                    <input type="file" accept="image/png, image/jpeg" multiple />
                </form>
            </div>
        </div>
    </>
);

export {HtmlForm, HtmlForm2, HtmlForm3, HtmlForm4};