import styled from 'styled-components';

const FirstLayer = styled.div`
    background-color: #ffa27a;
    padding: 1em;
`

const SecondLayer = styled.div`
    background-color: #ff7249;
    padding: 1em;
`

const ThirdLayer = styled.div`
    background-color: #ef2e2f;
    padding: 1em;
`

const WebsiteStorage = () => (
    <>
        <FirstLayer>
            Dedikovaný server
            <SecondLayer>
                Virtuálny server
                <ThirdLayer>
                    Hosting
                </ThirdLayer>
            </SecondLayer>
        </FirstLayer>
    </>
);

export default WebsiteStorage;