const SizeCSS = () => (
    <>
        <table style={{width: "100%"}}>
            <thead>
                <tr>
                    <th>Jednotka</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1<b>px</b></td>
                    <td>1 bod obrazovky</td>
                </tr>
                <tr>
                    <td>1<b>%</b></td>
                    <td>1% z nadradeného objektu</td>
                </tr>
                <tr>
                    <td>1<b>em</b></td>
                    <td>1 x velkosť písma v elemente</td>
                </tr>
                <tr>
                    <td>1<b>rem</b></td>
                    <td>1 x velkosť písma v dokumente {"<html>"}</td>
                </tr>
                <tr>
                    <td>1<b>vw</b>, 1<b>vh</b></td>
                    <td>1% šírky / výšky obrazovky</td>
                </tr>
                <tr>
                    <td>1<b>cm</b></td>
                    <td>1cm (použiť len pre tlač)</td>
                </tr>
            </tbody>
        </table>
    </>
);

export default SizeCSS;