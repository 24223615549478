import BAImage from "./assets/ba-img.jpg";

const BackgroundCSS = () => (
    <>
        <table style={{width: "100%", fontSize: "0.65em"}}>
            <tbody>
                <tr>
                    <td>background-image: url('/cesta/obr.jpg');</td>
                    <td>Vloženie pozadia</td>
                </tr>
                <tr>
                    <td>background-size: container | <b>cover</b> | auto;</td>
                    <td>Veľkosť pozadia</td>
                </tr>
                <tr>
                    <td>background-repeat: <b>no-repeat</b> | repeat | repeat-x | ...</td>
                    <td>Opakovanie pozadia</td>
                </tr>
                <tr>
                    <td>background-position: <b>center center</b> | bottom left | <br /> 20% 40% | ... </td>
                    <td>Pozícia pozadia</td>
                </tr>
            </tbody>
        </table>
        <br />
        <ul>
            <li>
                Univerzálne použitie:
                <ul>
                    <li>
                        background: url('') center center no-repeat;
                    </li>
                </ul>
            </li>
        </ul>
    </>
);

const BackgroundCSS2 = () => (
    <>
        <div className="row" style={{justifyContent: "space-around"}}>
            <div style={{fontSize: "0.65em"}}>
                Center center
                <div style={{color: "white", width: 200, height: 200, background: `url('${BAImage}') center center no-repeat`}} />
            </div>
            <div style={{fontSize: "0.65em"}}>
                Bottom left
                <div style={{color: "white", width: 200, height: 200, background: `url('${BAImage}') bottom left no-repeat`}} />
            </div>

            <div style={{fontSize: "0.65em"}}>
                70% 40%
                <div style={{color: "white", width: 200, height: 200, background: `url('${BAImage}') bottom left no-repeat`}} />
            </div>
                
        </div>
        <div className="row" style={{justifyContent: "space-around"}}>
            <div style={{fontSize: "0.65em"}}>
                -500% center repeat
                <div style={{color: "white", width: 200, height: 200, background: `url('${BAImage}') -500% center repeat`}} />
            </div>
            <div style={{fontSize: "0.65em"}}>
                Bottom left cover
                <div style={{color: "white", width: 200, height: 200, background: `url('${BAImage}') bottom left no-repeat`, backgroundSize: "cover"}} />
            </div>
        </div>
    </>
)

export {BackgroundCSS, BackgroundCSS2};