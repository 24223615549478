const HTMLTagsPair = () => (
    <>
        <div className="left">Nápis v dokumente</div>
        <ul>
            <li>
               Označený <b>{'<h[číslo]>'}</b> ako <b>h</b>eading
            </li>
            <li>
               Čislo učuje úroveň nápisu (h1 až h6)
            </li>
            <li>
               Nápis 1. úrovne musí byť v dokumente <b>práve jeden</b>
            </li>
        </ul>
        <pre style={{fontSize: "0.8em"}}>
            <code>
{`<h1>Školenie</h1>
<h2>Zoznam školení</h2>
<h3>Školenie programovania</h3>
<h4>Programovie 1</h4>
<h5>Programovanie 1 v Bratislave</h5>
<h6>Programovanie 1 v Ružinove</h6>
`}
            </code>
        </pre>
    </>
);

const HTMLTagsPair2 = () => (
    <>
        <div className="left">Odsek: <b>p</b>aragraph</div>
        <pre>
            <code>
{`<p>Toto je blok textu </p>
`}
            </code>
        </pre>
        <div className="left">Blok citátu: <b>{'<blockquote>'}</b> </div>
        <pre>
            <code>
{`<blockquote cite="https://wooacademy.sk/">
    Citujem autora Wooacademy 
</blockquote>
`}
            </code>
        </pre>
        <div className="left">Neutrálne tagy: <b>{'<div> <span>'}</b> </div>
        <pre>
            <code>
{`<div> ... <span> ... </span></div>`}
            </code>
        </pre>
    </>
);


const HTMLTagsPair3 = () => (
    <>
        <div className="left">Úprava textu</div>
        <ul>
            <li>
               <strong>Hrubé písmo</strong> <b>{'<b>'}</b> ako <b>b</b>old alebo <b>{'<strong>'}</b>
               <ul><li>Vizuálne sú znaky rovnaké, {'<strong>'} však indikuje dôležitosť</li></ul>
            </li>
            <li>
                <i>Kurzíva</i> <b>{'<i>'}</b> ako <b>i</b>talic
            </li>
            <li>
                <u>Podčiarknuté písmo</u> <b>{'<u>'}</b> ako <b>u</b>ndreline
            </li>
        </ul>

        <pre style={{fontSize: "1.3em"}}>
            <code>
{`<b>Texty môžeme</b> 
<i><u>kombinovať</u>
ľubovoľne</i>
`}
            </code>
        </pre>
    </>
);

const HTMLTagsPair4 = () => (
    <>
        <div className="left">Tvorba tabuliek <b>{'<table>'}</b></div>
        <pre>
            <code data-line-numbers="1,20|2,7,8,13,14,19|3,6,9,12,15,18|4,5|10,11,16,17">
{`<table> <!-- Začiatok tabuľky -->
    <thead> <!-- Záhlavie -->
        <tr> <!-- Jeden riadok -->
            <th>Mesiac</th> <!-- 1. Stĺpec -->
            <th>Suma</th>   <!-- 2. Stĺpec -->
        </tr>
    </thead>
    <tbody> <!-- Telo -->
        <tr>
            <td>Január</td> <!-- 1. Stĺpec -->
            <td>100</td>    <!-- 2. Stĺpec -->
        </tr>
    </tbody>
    <tfoot> <!-- Päta -->
        <tr>
            <td>Spolu</td>
            <td>100</td>
        </tr>
    </tfoot> 
</table> <!-- Koniec tabuľky -->
`}
            </code>
        </pre>
    </>
);

const HTMLTagsPair5 = () => (
    <>
        <div className="left">Tvorba tabuliek <b>{'<table>'}</b></div>
        <table style={{border: "1px solid #fff", marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Mesiac</th>
                    <th>Suma</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Január</td>
                    <td>100</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>Spolu</td>
                    <td>100</td>
                </tr>
            </tfoot>
        </table>
    </>
);

const HTMLTagsPair6 = () => (
    <>
        <div className="left">Spájanie stĺpcov a riadkov v tabuľke</div>
        <table style={{border: "1px solid #fff", marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Mesiac</th>
                    <th>Suma</th>
                </tr>
                <tr>
                    <th colSpan="2">Spojené stĺpce</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Január</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td>Február</td>
                    <td rowSpan="2">Spojené riadky</td>
                </tr>
                <tr>
                    <td>Marec</td>
                </tr>
                <tr>
                    <td>Apríl</td>
                    <td>200</td>
                </tr>
            </tbody>
        </table>
    </>
);

const HTMLTagsPair7 = () => (
    <>
        <div className="left">Spájanie stĺpcov a riadkov v tabuľke</div>
        <pre>
            <code data-line-numbers="4,5,8|18,21">
{`<table>
    <thead>
        <tr>
            <th>Mesiac</th>
            <th>Suma</th>
        </tr>
        <tr>
            <th colspan="2">Spojené stĺpce</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Január</td>
            <td>100</td>
        </tr>
        <tr>
            <td>Február</td>
            <td rowspan="2">Spojené riadky</td>
        </tr>
        <tr>
            <td>Marec</td>
        </tr>
        <tr>
            <td>Apríl</td>
            <td>200</td>
        </tr>
    </tbody>
</table>
`}
            </code>
        </pre>
    </>
);

const HTMLTagsPair8 = () => (
    <>
        <div className="left">Zoznamy bez poradia - <b>{'<ul>'}</b></div>
        <div className="row">
            <div className="col">
            <pre>
            <code data-line-numbers="1,8|2,4,5,7">
{`<ul>
    <li>
        Položka 1
    </li>
    <li>
        Položka 2
    </li>
</ul>
`}
            </code>
        </pre>
            </div>
            <div className="col" style={{alignItems: "center"}}>
                <ul>
                    <li>
                        Položka 1
                    </li>
                    <li>
                        Položka 2
                    </li>
                </ul>
            </div>
        </div>
       
    </>
);

const HTMLTagsPair9 = () => (
    <>
        <div className="left">Zoznamy s poradím - <b>{'<ol>'}</b></div>
        <div className="row">
            <div className="col">
            <pre>
            <code data-line-numbers="1,8|2,4,5,7">
{`<ol>
    <li>
        Položka 1
    </li>
    <li>
        Položka 2
    </li>
</ul>
`}
            </code>
        </pre>
            </div>
            <div className="col" style={{alignItems: "center"}}>
                <ol>
                    <li>
                        Položka 1
                    </li>
                    <li>
                        Položka 2
                    </li>
                </ol>
            </div>
        </div>
       
    </>
);

const HTMLTagsPair10 = () => (
    <>
        <div className="left">Zoznam v zozname v zozname</div>

            <pre>
            <code data-line-numbers="1-24|4-19|7-14">
{`<ul>
    <li>
        Položka 1
        <ul>
            <li>
                Položka 1
                <ol>
                    <li>
                        Položka 1
                    </li>
                    <li>
                        Položka 2
                    </li>
                </ol>
            </li>
            <li>
                Položka 2
            </li>
        </ul>
    </li>
    <li>
        Položka 2
    </li>
</ul>
`}
            </code>
        </pre>
    </>
);


const HTMLTagsUnpair = () => (
    <>
        <div className="left">Nový riadok: <b>{'<br />'}</b></div>
        <div className="left">Obrázok: <b>{'<img />'}</b> </div>
        <pre>
            <code>
{`<img src="/cesta/k/obrazku" alt="Nazov" />
`}
            </code>
        </pre>
        <div className="left">Separátor: <b>{'<hr />'}</b> </div>
        <div className="left">Skrytý komentár:</div>
        <pre>
            <code>
{`<!-- Toto nikto neuvidí --> A toto áno`}
            </code>
        </pre>
    </>
);

export {
    HTMLTagsPair, HTMLTagsPair2, HTMLTagsPair3, HTMLTagsPair4, 
    HTMLTagsPair5, HTMLTagsPair6, HTMLTagsPair7, HTMLTagsPair8, 
    HTMLTagsPair9, HTMLTagsPair10,
    HTMLTagsUnpair
};