const NeutralTags = () => (
    <>
        <ul>
            <li>Neobsahujú špecifickú úpravu</li>
            <ul>
                <li>
                    Ako napr. tagy {"<b>, <i>, ..."}
                </li>
            </ul>
            <li>Pomáhaju CSS selektovať element</li>
            <li>Delia sa na <b>blokové</b> a <b>inlinové</b></li>
            <ul>
                <li>Použitie podľa situácie</li>
            </ul>
        </ul>
        <br />
        <div className="row">
            <div className="col left">
                <b>{"<div> ... </div>"}</b>
                <div style={{color: "green"}}>
                    Blokový 
                    <div style={{color: "blue"}}>
                        element 
                    </div>
                </div>
            </div>
            <div className="col left">
                <b>{"<span> ... </span>"}</b>
                <span style={{color: "green"}}>Inline-ový <span style={{color: "blue"}}>element</span></span> 
            </div>
        </div>
    </>
);

export default NeutralTags;