const EXOptimalization = () => (
    <>
        <ul>
            <li>Izolujte a optimalizujte CSS</li>
            <li>Optimalizujte obrázky</li>
            <li>Nastavte META údaje (vrátane OG a favicon)</li>
            <li>Nahrajte stránku na server</li>
            <li>Optimalizujte stránku pre tlač</li>
            <li>Otestujte Vašu stránku
                <ul>
                    <li><a href="https://developers.google.com/speed/pagespeed/insights/?hl=sk" target="_blank" rel="noreferrer">
                Google PageSpeed Insights</a></li>
                <li>
                    <a href="https://developers.facebook.com/tools/debug" target="_blank" rel="noreferrer">Facebook share debugger</a>
                </li>
                </ul>
            </li>
        </ul>
    </>
);

export default EXOptimalization;