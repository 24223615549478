const PrintCSS = () => (
    <>
        <pre>
            <code>
{`.moj-element {
  font-family: Verdana, arial, sans-serif;
}
@media print {
  .moj-element {
    font-family: Georgia, times, serif;
  }
}
`}               
            </code>
        </pre>
    </>
)

const PrintCSS2 = () => (
  <>
    <ul><li>Zalamovanie strán (nefunguje na flex)</li></ul>
      <pre>
          <code>
{`.article {
  page-break-after: always;
  page-break-before: auto;
}
`}               
          </code>
      </pre>
      <ul><li>Fixná pozícia je na každej strane</li></ul>
      <pre>
          <code>
{`footer {
  position: fixed; bottom: 0; width: 100%;
}
`}               
          </code>
      </pre>
  </>
)

export {PrintCSS, PrintCSS2};