import FeBeImage from "./assets/febe.jpeg";

const FeBe = () => (
    <div className="row">
        <div className="col">
            <ul>
                <li><b>Front-end</b> (skratkou FE)</li>
                <ul>
                    <li>Vizuálna časť stránky</li>
                    <li>HTML, CSS, JavaScript</li>
                </ul>
                <li>Back-end (skratkou BE)</li>
                <ul>
                    <li>Funkčná časť stránky</li>
                    <li>PHP, MySQL, .NET</li>
                </ul>
            </ul>
        </div>
        <div className="col">
            <img width="350" src={FeBeImage} alt="Fe-Be" style={{margin: 0, alignSelf: "flex-end",}} />
        </div>
        
    </div>
);

export default FeBe;