
const EXHTMLStruct = () => (
    <>  
        <div className="left">
            Upravte <b>index.html</b> nasledovne:
        </div>
        <div className="row" style={{marginTop: ".5em"}}>
            <div className="col">
                <ol>
                    <li>
                        Navigácia
                        <ul>
                            <li>Domov, Leto, Zima, Gdpr, Kontakt (e-mail)</li>
                        </ul>
                    </li>
                    <li>
                        Záhlavie
                        <ul>
                            <li>Názov - „Moje záľuby“</li>
                        </ul>
                    </li>
                    <li>
                        Hlavná časť - Leto
                        <ul>
                            <li>Text</li>
                            <li>Zoznam záľub</li>
                            <li>Video</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <div className="col">
                <ol start="3">
                    <li>
                        Hlavná časť - Zima
                        <ul>
                            <li>Text</li>
                            <li>Zoznam záľub</li>
                            <li>Obrázok</li>
                        </ul>
                    </li>
                    <li>
                        Päta
                        <ul>
                            <li>Predeľovač</li>
                            <li>Copyright 2021</li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        
    </>
);

const EXHTMLStruct2 = () => (
    <>
        <div className="left">
            <ul>
                <li>
                    <a href="/files/moja-stranka-priklad.pdf" target="_blank" rel="noreferrer">PDF príklad stránky</a>
                </li>
                <li>
                    <a href="https://pixabay.com/sk/" target="_blank" rel="noreferrer">Zdroj fotografií a videí - Pixabay.com</a>
                </li>
                <li>
                    <a href="https://loremipsum.io/generator/" target="_blank" rel="noreferrer">Generátor textu - Loremipsum.io</a>
                </li>
                <li>
                    Použité tagy: <br /> 
                    {'<p> <nav> <header> <main> <section> <article> <img> <video> <hr /> <b> <a> <h1> <h2> <ul> <li>'}
                </li>
                <li>Súbory</li>
                <ul>
                    <li>index.html</li>
                    <li>img a video priečinok</li>
                </ul>
            </ul>
        </div>
    </>
);

export {EXHTMLStruct, EXHTMLStruct2};