import { AiFillWindows, AiFillApple } from "react-icons/ai";
import { DiLinux } from "react-icons/di";
import ElementorVideo from "./assets/elementor.mp4";
import FroalaImage from "./assets/froala.gif";
import VSCodeImage from "./assets/vscode.gif";

const WYSIWYGEditor = () => (
    <>
        <section>
            <img src={FroalaImage} alt="Froala editor" width="800" />
        </section>
        <section>
            <div className="left">
                
            </div>
            <ul>
                <li>
                    Ako <b>súčasť webovej stránky</b>, zvyčajne prekladá vstup do kódu
                    <ul>
                        <li>
                            <a href="https://ckeditor.com/" rel="noreferrer" target="_blank">CKEditor</a>
                        </li>
                        <li>
                            <a href="https://quilljs.com/" rel="noreferrer" target="_blank">Quill</a>
                        </li>
                        <li>
                            <a href="https://www.tiny.cloud/" rel="noreferrer" target="_blank">TinyMCE</a>
                        </li>
                    </ul>
                </li>
                <li>
                    Ako inštalovateľná <b>aplikácia</b>, zvyčajne prekladá vstup do formátu
                    <ul>
                        <li>
                            <a href="https://www.office.com/" rel="noreferrer" target="_blank">MS Office</a>
                        </li>
                        <li>
                            <a href="https://www.libreoffice.org/" rel="noreferrer" target="_blank">Libre Office</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </>
);

const PageBuilder = () => (
    <>
        <section>
            <video data-autoplay alt="Elementor video" >
                <source data-src={ElementorVideo} type="video/mp4"/>
            </video>
        </section>
        <section>
            <div className="left">
                <b>Aplikácia</b> pre tvorbu webových stránok.
            </div>
            <ul>
                <li>
                    Ako webový systém
                    <ul>
                        <li>
                            <a href="https://cs.wix.com/" rel="noreferrer" target="_blank">Wix</a>
                        </li>
                        <li>
                            <a href="https://wordpress.org/" rel="noreferrer" target="_blank">WordPress</a>
                        </li>
                        <li>
                            <a href="https://www.webnode.sk/" rel="noreferrer" target="_blank">Webnode</a>
                        </li>
                    </ul>
                </li>
                <li>
                    Ako inštalovateľný publikačný nástroj
                    <ul>
                        <li>
                            <a href="https://mobirise.com/" rel="noreferrer" target="_blank">Mobirise <AiFillWindows /> <AiFillApple /></a>
                        </li>
                        <li>
                            <a href="https://pingendo.com/" rel="noreferrer" target="_blank">Pingendo <AiFillWindows /> <AiFillApple /> <DiLinux /></a>
                        </li>
                        <li>
                            <a href="https://webflow.com/" rel="noreferrer" target="_blank">Webflow <AiFillWindows /> <AiFillApple /></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </>
);

const CodeEditor = () => (
    <>
        <section>
            <img src={VSCodeImage} alt="VS Code editor" width="900" />
        </section>
        <section>
            <div className="left">
                Určený pre prehľadnú <b>úpravu kódu</b>.
            </div>
            <ul>
                <li>
                    Oproti WYSIWYG Editorom
                    <ul>
                        <li>
                            Farebne odlišuje a validuje časti kódu
                        </li>
                        <li>
                            Obsahuje nápovedu rozpísaného príkazu
                        </li>
                        <li>
                            Umožňuje vkladanie vlastných príkazov a pluginov
                        </li>
                    </ul>
                </li>
                <li>
                    Stiahnuteľný zväčša bezplatne
                    <ul>
                        <li>
                            <a href="https://code.visualstudio.com/" rel="noreferrer" target="_blank">VS Code <AiFillWindows /> <AiFillApple /> <DiLinux /></a>
                        </li>
                        <li>
                            <a href="https://atom.io/" rel="noreferrer" target="_blank">Atom <AiFillWindows /> <AiFillApple /> <DiLinux /></a>
                        </li>
                        <li>
                            <a href="https://www.jetbrains.com/" rel="noreferrer" target="_blank">JetBrains <i>(platené)</i> <AiFillWindows /> <AiFillApple /> <DiLinux /></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </>
);

export {WYSIWYGEditor, PageBuilder, CodeEditor};