import Editors from "./Editors";
import { CodeEditor, PageBuilder, WYSIWYGEditor } from "./Editors/editorList";
import HtmlElements from "./HtmlElements";
import Intro from "./Intro";
import Thanks from "./Thanks";
import Website from "./Website";
import {WebsiteAccess, WebsiteAccess2, WebsiteAccess3, WebsiteAccess4} from "./WebsiteAccess";
import {WebsiteIntro,WebsiteIntro2} from "./WebsiteIntro";
import FeBe from "./FeBe";
import IndexFile from "./WebsiteCreate/IndexFile";
import WebsiteStorage from "./WebsiteStorage";
import HTMLIntro from "./HTMLIntro";
import EXHTMLChars from "./EXHTMLChars";
import { 
    HTMLTagsPair, HTMLTagsPair2, HTMLTagsPair3, HTMLTagsPair4, HTMLTagsPair5, HTMLTagsPair6, 
    HTMLTagsPair7, HTMLTagsPair8, HTMLTagsPair9, HTMLTagsPair10,
} from "./HTMLIntro/pairTags";
import HTMLTagsUnpair from "./HTMLIntro/unpairTags";
import AboutMe from "./Intro/aboutMe";
import AboutAcademy from "./Intro/aboutAcademy";
import {Contents, Contents2, Contents3} from "./Intro/contents";
import WebsiteDescription from "./Website/websiteDescription";
import {AboutHTML, HTMLTags} from "./HTMLIntro/aboutHTML";
import {HtmlRedirect, HtmlRedirect2, HtmlRedirect3, HtmlRedirect4} from "./HTMLIntro/htmlRedirect";
import {HtmlStructure, HtmlStructure2} from "./HTMLIntro/htmlStructure";
import {HtmlForm, HtmlForm2, HtmlForm3, HtmlForm4} from "./HTMLIntro/htmlForm";
import {HtmlMedia,HtmlMedia2,HtmlMedia3} from "./HTMLIntro/htmlMedia";
import WebsiteCreate from "./WebsiteCreate";
import {EXHTMLStruct, EXHTMLStruct2} from "./EXHTMLStruct";
import { CSSIntro } from "./CSS";
import { AboutCSS, AboutCSS2 } from "./CSS/aboutCSS";
import { UsingCSS, UsingCSS2 } from "./CSS/usingCSS";
import { SelectorCSS, SelectorCSS2, SelectorCSS3, SelectorCSS4 } from "./CSS/selectorCSS";
import { CascadesCSS, CascadesCSS2, CascadesCSS3, CascadesCSS4, CascadesCSS5, CascadesCSS6 } from "./CSS/cascadesCSS";
import SizeCSS from "./CSS/sizeCSS";
import ColorsCSS from "./CSS/colorsCSS";
import KeysCSS from "./CSS/keysCSS";
import NeutralTags from "./CSS/neutralTags";
import {PositionsCSS, PositionsCSS2, PositionsCSS3, PositionsCSS4} from "./CSS/positionsCSS";
import {MarginCSS, MarginCSS2} from "./CSS/marginCSS";
import {SpecialMarksCSS, SpecialMarksCSS2} from "./CSS/specialMarksCSS";
import {ResponsivityCSS, ResponsivityCSS2} from "./CSS/responsivityCSS";
import { CSS3, CSS32, CSS33 } from "./CSS/css3";
import {AnimationCSS, AnimationCSS2, AnimationCSS3} from "./CSS/animationCSS";
import {TransitionCSS, TransitionCSS2} from "./CSS/transitionCSS";
import ExCssChars from "./EXCSS/exCssChars";
import ExCssWebsite from "./EXCSS/exCssWebsite";
import Optimalization from "./Optimalization";
import Meta from "./Optimalization/meta";
import Favicon from "./Optimalization/favicon";
import {SEO, SEO2} from "./Optimalization/seo";
import {Social, Social2} from "./Optimalization/social";
import HTMLPositionCSS from "./CSS/htmlPositionCSS";
import {SeparateCSS, SeparateCSS2} from "./Optimalization/separateCSS";
import ImportManagement from "./Optimalization/importManagement";
import DisplayCSS from "./CSS/displayCSS";
import {FlexCSS, FlexCSS2, FlexCSS3, FlexCSS4} from "./CSS/flexCSS";
import FrameworksCSS from "./Optimalization/frameworksCSS";
import Analyse from "./Optimalization/analyse";
import {ImageOptimise, ImageOptimise2,ImageOptimise3} from "./Optimalization/imageOptimise";
import EXOptimalization from "./EXOptimalization";
import CheckList from "./CheckList";
import UsabilityToolsHTML from "./UsabilityToolsHTML";
import WidthHeightCSS from "./CSS/widthHeightCSS";
import ContainerCSS from "./CSS/containerCSS";
import {BackgroundCSS, BackgroundCSS2} from "./CSS/backgroundCSS";
import FontsCSS from "./CSS/fontsCSS";
import {PrintCSS, PrintCSS2} from "./CSS/printCSS";
import DisabledPeople from "./Optimalization/disabledPeople";
import Coureses from "./Courses";

const structure = [
    {
        title: "Úvod",
        content: <Intro />,
        type: "empty",
        sub: [
            {
                title: "Kto prednáša?",
                content: <AboutMe />,
            },
            {
                title: "O nás",
                content: <AboutAcademy />,
            },
            {
                title: "Čo nás čaká?",
                content: <Contents />,
                sub: [<Contents2 />, <Contents3 />]
            },
        ]
    },
    {
        title: "Úvod to sveta webu",
        content: <Website title={<>Úvod do <br /><b>sveta webu</b></>} />,
        type: "empty",
        sub: [
            {
                title: "Čo je webová stránka?",
                content: <WebsiteDescription />
            },
            {
                title: "Strany všednej stránky",
                content: <FeBe />,
            },
            {
                title: "Kde je uložená moja stránka?",
                content: <WebsiteStorage />,
            },
            {
                title: "Ako získam prístup na stránku?",
                content: <WebsiteAccess />,
                sub: [
                    <WebsiteAccess2 />, <WebsiteAccess3 />, <WebsiteAccess4 />,
                ]
            },
        ]
    },
    {
        title: "Nástroje tvorby kódu",
        content: <Editors title={<>Nástroje <br /><b>tvorby kódu</b></>} />,
        type: "empty",
        sub: [
            {
                title: "Page Builder",
                content: <PageBuilder />,
            },
            {
                title: "WYSIWYG Editor",
                content: <WYSIWYGEditor />,
            },
            {
                title: "Code Editor",
                content: <CodeEditor />,
            },
        ]
    },
    {
        title: "Jazyk HTML",
        content: <HTMLIntro title={<>Jazyk <br /><b>HTML</b></>} />,
        type: "empty",
        sub: [
            {
                title: "Čo je HTML?",
                content: <AboutHTML />,
            },
            {
                title: "Delenie tagov",
                content: <HTMLTags />,
            },
            {
                title: "Párové tagy",
                content: <HTMLTagsPair />,
                sub: [
                    <HTMLTagsPair2 />, <HTMLTagsPair3 />, <HTMLTagsPair4 />, <HTMLTagsPair5 />, 
                    <HTMLTagsPair6 />,<HTMLTagsPair7 />,<HTMLTagsPair8 />,<HTMLTagsPair9 />,
                    <HTMLTagsPair10 />,
                ]
            },
            {
                title: "Nepárové tagy",
                content: <HTMLTagsUnpair />,
            },
            {
                title: "Presmerovanie / Hypertext",
                content: <HtmlRedirect />,
                sub: [<HtmlRedirect2 />, <HtmlRedirect3 />, <HtmlRedirect4 />]
            },
            {
                title: "Formuláre",
                content: <HtmlForm />,
                sub: [<HtmlForm2 />, <HtmlForm3 />, <HtmlForm4 />]
            },
            {
                title: "Multimediálny obsah",
                content: <HtmlMedia />,
                sub: [<HtmlMedia2 />, <HtmlMedia3 />]
            },
        ]
    },
    {
        title: "Tvorba webovej stránky",
        content: <WebsiteCreate title={<>Tvorba <br /><b>webovej stránky</b></>} />,
        type: "empty",
        sub: [
            {
                title: "Štruktúra webovej stránky",
                content: <HtmlStructure />,
                sub: [<HtmlStructure2 />]
            },
            {
                title: "Tvoríme vlastnú stránku",
                content: <WebsiteIntro />,
                sub: [<WebsiteIntro2 />]
            },
            {
                title: "Prečo index?",
                content: <IndexFile />,
            },
            {
                title: "Cvičenie 1: Znaky v HTML",
                content: <EXHTMLChars />,
            },
            {
                title: "Cvičenie 2: Moje záľuby",
                content: <EXHTMLStruct />,
                sub: [<EXHTMLStruct2 />]
            },
        ],
    },
    {
        title: "CSS - Kaskádové štýly",
        content: <CSSIntro title={<>Kaskádové štýly <br /><b>CSS</b></>} />,
        type: "empty",
        sub: [
            {
                title: "Čo je CSS?",
                content: <AboutCSS />,
                sub: [<AboutCSS2 />,]
            },
            {
                title: "Kde sa na stránke nachádza?",
                content: <HTMLPositionCSS />,
            },
            {
                title: "Základná syntax",
                content: <UsingCSS />,
                sub: [<UsingCSS2 />]
            },
            {
                title: "Jednotky veľkosti",
                content: <SizeCSS />,
            },
            {
                title: "Definícia farieb",
                content: <ColorsCSS />,
            },
            {
                title: "Základné štýlovacie kľúče",
                content: <KeysCSS />,
            },
            {
                title: "Selektory jazyka",
                content: <SelectorCSS />,
                sub: [<SelectorCSS2 />,<SelectorCSS3 />,<SelectorCSS4 />,]
            },
            {
                title: "Neutrálne štýlovacie tagy",
                content: <NeutralTags />,
            },
            {
                title: "Kaskádové štýlovanie",
                content: <CascadesCSS />,
                sub: [<CascadesCSS2 />, <CascadesCSS3 />, <CascadesCSS4 />, <CascadesCSS5 />, <CascadesCSS6 />]
            },
            {
                title: "Šírka a dĺžka blokov",
                content: <WidthHeightCSS />,
            },
            {
                title: "Okraje objektov",
                content: <MarginCSS />,
                sub: [<MarginCSS2 />]
            },
            {
                title: "Kontajner stránky",
                content: <ContainerCSS />,
            },
            {
                title: "Pozície objektov",
                content: <PositionsCSS />,
                sub: [<PositionsCSS2 />, <PositionsCSS3 />, <PositionsCSS4 />]
            },
            {
                title: "Blokové a obsahové usporiadanie",
                content: <DisplayCSS />,
            },
            {
                title: "Flexibilné usporiadanie",
                content: <FlexCSS />,
                sub: [<FlexCSS2 />, <FlexCSS3 />, <FlexCSS4 />]
            },
            {
                title: "Pseudo-triedy",
                content: <SpecialMarksCSS />,
                sub: [<SpecialMarksCSS2 />]
            },
            {
                title: "Obrázkové pozadie",
                content: <BackgroundCSS />,
                sub: [<BackgroundCSS2 />]
            },
            {
                title: "Štýl písma",
                content: <FontsCSS />,
            },
            {
                title: "Responzivita",
                content: <ResponsivityCSS />,
                sub: [<ResponsivityCSS2 />]
            },
            {
                title: "Verzia pre tlač",
                content: <PrintCSS />,
                sub: [<PrintCSS2 />]
            },
            {
                title: "Pokročilé štýlovanie",
                content: <CSS3 />,
                sub: [<CSS32 />, <CSS33 />]
            },
            {
                title: "Animácia objektov",
                content: <AnimationCSS />,
                sub: [<AnimationCSS2 />, <AnimationCSS3 />]
            },
            {
                title: "Animácia prechodu",
                content: <TransitionCSS />,
                sub: [<TransitionCSS2 />]
            },
            {
                title: "Cvičenie 3: Znaky v CSS",
                content: <ExCssChars />,
            },
            {
                title: "Cvičenie 4: Štýlovanie webovej stránky",
                content: <ExCssWebsite />,
            },
        ]
    },
    {
        title: "Optimalizácia stránky",
        content: <Optimalization title={<>Optimalizácia <br /><b>stránky</b></>} />,
        type: "empty",
        sub: [
            {
                title: "META údaje",
                content: <Meta />,
            },
            {
                title: "Favicon - ikona karty",
                content: <Favicon />,
            },
            {
                title: "Základné SEO",
                content: <SEO />,
                sub: [<SEO2 />]
            },
            {
                title: "Sociálne siete",
                content: <Social />,
                sub: [<Social2 />]
            },
            {
                title: "Oddelenie štýlovania",
                content: <SeparateCSS />,
                sub: [<SeparateCSS2 />]
            },
            {
                title: "Optimalizácia obrázkov",
                content: <ImageOptimise />,
                sub: [<ImageOptimise2 />,<ImageOptimise3 />]
            },
            {
                title: "Optimalizácia importov",
                content: <ImportManagement />,
            },
            {
                title: "Použitie štýlovacích framework-ov",
                content: <FrameworksCSS />,
            },
            {
                title: "Analýza webovej stránky",
                content: <Analyse />,
            },
            {
                title: "Pre zrakovo postihnutých",
                content: <DisabledPeople />,
            },
            {
                title: "Cvičenie 5: Finálne úpravy",
                content: <EXOptimalization />,
            },
        ]
    },
    {
        title: "Checklist",
        content: <CheckList />,
    },
    {
        title: "Ďalšie užitočné nástroje",
        content: <UsabilityToolsHTML />,
    },
    {
        title: "Naše kurzy",
        content: <Coureses />,
    },
    {
        title: "Ďakujeme",
        content: <Thanks title={<>ĎAKUJEME</>} />,
        type: "empty",
    },
];

export default structure;