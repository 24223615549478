import WebsiteImage from "./assets/website-overlay.png";

const WebsiteDescription = () => (
    <>
       <div className="row">
           <div className="col">
                <ul>
                    <li>
                        <b>Dokument</b>
                    </li>
                    <li>
                        Vytvorený v <b>HTML</b>
                        <ul>
                            <li>Alebo jeho generátorom</li>
                        </ul>
                    </li>
                    <li>
                        Zobrazený v prehliadači
                    </li>
                    <li>
                        Privátny aj verejný
                    </li>
                    <li>
                        Statický aj dynamický
                    </li>
                </ul>
           </div>
           <div className="col">
               <img src={WebsiteImage} alt="Website" />
           </div>
       </div>
    </>
);

export default WebsiteDescription;