import MonaLisaImg from "./assets/mona-lisa.jpg"
import VideoLoop from "./assets/video.mp4"

const HtmlMedia = () => (
    <>  
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`<img 
  src="/cesta/obr/mona-lisa.jpg"
  alt="Mona Lisa"
  width="400"
  height="980"
/>  
`}
                    </code>
                </pre>
            </div>
            <div className="col">
                <img src={MonaLisaImg} alt="Mona Lisa Corona" />
            </div>
        </div>
    </>
);

const HtmlMedia2 = () => (
    <>  
        <div className="row">
            <div className="col">
                <pre style={{fontSize: ".6em"}}>
                    <code>
{`<video 
  width="320" 
  height="600" 
  controls muted
  loop autoplay
  poster="/url/obrazka"
>
  <source 
    src="video.mp4" 
    type="video/mp4"
  />
  Video nepodporované!
</video> 
`}
                    </code>
                </pre>
            </div>
            <div className="col">
                <video poster={MonaLisaImg} muted controls loop data-autoplay src={VideoLoop} style={{marginTop: "1em"}}></video>
            </div>
        </div>
    </>
);

const HtmlMedia3 = () => (
    <>  
        <div className="row">
            <div className="col">
                <pre style={{fontSize: ".6em"}}>
                    <code>
{`<iframe 
  width="400" 
  height="600" 
  src="/url/adresa/zdroja"
  title="Me at the zoo"
>
</iframe> 
`}
                    </code>
                </pre>
            </div>
            <div className="col">
                <iframe title="Me at the zoo" width="560" height="315" src="https://www.youtube.com/embed/jNQXAC9IVRw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    </>
);

export {HtmlMedia, HtmlMedia2, HtmlMedia3};