import FontsImage from "./assets/fonts.png";

const FontsCSS = () => (
    <>
        <ul>
            <li>
                Najdostupnejšie z <a href="https://fonts.google.com/" target="_blank" rel="noreferrer">Google Fonts</a>
            </li>
        </ul>
        <img src={FontsImage} alt="Fonts" />
    </>
)

export default FontsCSS;