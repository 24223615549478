import OGImage from "./assets/og-wooacademy.png";
import ShareDebuggerImage from "./assets/share-debugger.png";

const Social = () => (
    <>
        <ul>
            <li>Prostredníctvom OG Meta údajov
                <ul>
                    <li>{'<meta property="Element" content="Obsah">'}</li>
                    <li><a href="https://ogp.me/" target="_blank" rel="noreferrer">Dokumentácia všetkých tagov</a></li>
                </ul>
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <table style={{width: "100%", fontSize: "0.65em"}}>
                    <thead>
                        <tr>
                            <th>Element</th>
                            <th>Obsah</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>og:url</td>
                            <td>URL adresa</td>
                        </tr>
                        <tr>
                            <td>og:type</td>
                            <td>Typ (website, ...)</td>
                        </tr>
                        <tr>
                            <td>og:title</td>
                            <td>Názov</td>
                        </tr>
                        <tr>
                            <td>og:description</td>
                            <td>Popis</td>
                        </tr>
                        <tr>
                            <td>og:image</td>
                            <td>Cesta k obrázku</td>
                        </tr>
                        <tr>
                            <td>og:locale</td>
                            <td>Jazyk ("sk_SK")</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col">
                <img src={OGImage} alt="OG Wooacademy" />
            </div>
        </div>
    </>
);

const Social2 = () => (
    <>
        <ul>
            <li><a href="https://developers.facebook.com/tools/debug" target="_blank" rel="noreferrer">Facebook share debugger</a></li>
            <ul>
                <li>Náhľad stránky</li>
                <li>Opätovné načítanie OG v prípade zmeny</li>
            </ul>
        </ul>
        <img src={ShareDebuggerImage} alt="Facebook Share Debugger" />
    </>
)

export {Social, Social2};