import { useState } from "react";
import PropTypes from "prop-types";
import { CgMenuGridO } from "react-icons/cg";
import "./index.scss";
import PrimaryLogo from "../../images/primary-logo.png";

const Navigation = (props) => {
    const [isActive, setActive] = useState(false);
    const {items} = props;

    const navItems = [];
    for(let i = 0; i < items.length; i++) {
        let subnav;
        if(items[i].subnav && items[i].subnav.length > 0) {
            const _subnav_items = [];
            for(let k = 0; k < items[i].subnav.length; k++) {
                _subnav_items.push(
                    <li key={`subav-${i}-${k}`}>
                        <a href={`/#/${items[i].subnav[k].slide}`}>
                            {items[i].subnav[k].title} <span className="slide-num">{items[i].subnav[k].slide}</span>
                        </a>
                    </li>
                )
            }
            subnav = (
                <ul className="secondary-nav" key={`subav-${i}`}>
                   {_subnav_items} 
                </ul>
            )
        }
        navItems.push(
            <li key={`nav-${i}`}>
                <a href={`/#/${items[i].slide}`}>
                    {items[i].title} <span className="slide-num">{items[i].slide}</span>
                </a>
            </li>
        )
        if(subnav) {
            navItems.push(subnav)
        }
    }

    return (
        <>
            <div className="menu-icon" role="button" onClick={()=>setActive(true)}>
                <CgMenuGridO />
            </div>
            <div className={`sidebar ${isActive ? "active" : ""}`}>
                <div className="overlay" onClick={()=>setActive(false)} />
                <div className="nav-content">
                    <div className="nav-brand">
                        <img src={PrimaryLogo} alt="primary-logo" />
                    </div>
                    <ul className="nav-list">
                        {navItems}
                    </ul>
                    <hr />
                    <a className="download-button" href="/files/HTML_CSS_Wooacademy.pdf" rel="noreferrer" target="_blank">Stiahnúť PDF (13,9 MB)</a>
                </div>
            </div>
        </>
    )
};

Navigation.propTypes = {
    items: PropTypes.array.isRequired,
}

export default Navigation;