import { useState } from "react";
import styled from "styled-components";
import {ImCross} from "react-icons/im";
import {FaCheck} from "react-icons/fa";

const Input = styled.input`
    width: 70px;
    text-align: center;
    font-size: 1em;
    padding: 0, 10px;
    margin: 0 20px;
`

const EXHTMLChars = () => {

    const [values, setValues] = useState({
        leftPar: "", rightPar: "", comma: "", slash: "", equal: "", sepa: ""
    });

    function inputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setValues({
            ...values,
            [name]: value
        });
    }

    const success = <span className="success"><FaCheck /></span>;
    const error = <span className="danger"><ImCross /></span>;

    return (
        <>
            <div className="left">
                Opíšte uvedené znaky do polí.
            </div>
            <div className="row" style={{marginTop: "1em"}}>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>{"<"}</label>
                        <Input type="text" name="leftPar" value={values.leftPar} onChange={inputChange} />
                        {values.leftPar === "" ? "" : (values.leftPar === "<" ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>{">"}</label>
                        <Input type="text" name="rightPar" value={values.rightPar} onChange={inputChange} />
                        {values.rightPar === "" ? "" : (values.rightPar === ">" ? success : error)}
                    </div>
                </div>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>"</label>
                        <Input type="text" name="comma" value={values.comma} onChange={inputChange} />
                        {values.comma === "" ? "" : (values.comma === '"' ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>/</label>
                        <Input type="text" name="slash" value={values.slash} onChange={inputChange} />
                        {values.slash === "" ? "" : (values.slash === '/' ? success : error)}
                    </div>
                </div>
                <div className="col">
                    <div style={{display: "flex"}}>
                        <label>=</label>
                        <Input type="text" name="equal" value={values.equal} onChange={inputChange} />
                        {values.equal === "" ? "" : (values.equal === '=' ? success : error)}
                    </div>
                    <br />
                    <div style={{display: "flex"}}>
                        <label>-</label>
                        <Input type="text" name="sepa" value={values.sepa} onChange={inputChange} />
                        {values.sepa === "" ? "" : (values.sepa === '-' ? success : error)}
                    </div>
                </div>
            </div>
        </>
    )
};

export default EXHTMLChars;