const ColorsCSS = () => (
    <>
        <table style={{width: "100%"}}>
            <thead>
                <tr>
                    <th>Farba</th>
                    <th>Zápis</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div style={{width: 50, height: 50, backgroundColor: "red"}} />
                    </td>
                    <td>red</td>
                    <td>Určenie farby slovom</td>
                </tr>
                <tr>
                    <td>
                        <div style={{width: 50, height: 50, backgroundColor: "#FF0000"}} />
                    </td>
                    <td>#FF0000</td>
                    <td>Hexadecimálny zápis</td>
                </tr>
                <tr>
                    <td>
                        <div style={{width: 50, height: 50, backgroundColor: "rgb(255,0,0)"}} />
                    </td>
                    <td>rgb(255,0,0)</td>
                    <td>Zápis RGB</td>
                </tr>
                <tr>
                    <td>
                        <div style={{width: 50, height: 50, backgroundColor: "rgba(255,0,0,1)"}} />
                        <div style={{width: 50, height: 50, backgroundColor: "rgba(255,0,0,0.5)"}} />
                        <div style={{width: 50, height: 50, backgroundColor: "rgba(255,0,0,0.1)"}} />
                    </td>
                    <td>
                        rgba(255,0,0,1) <br />
                        rgba(255,0,0,0.5) <br />
                        rgba(255,0,0,0.1)
                    </td>
                    <td>Zápis RGB + Alpha (viditeľnosť)</td>
                </tr>
            </tbody>
        </table>
    </>
);

export default ColorsCSS;