const ImportManagement = () => (
    <>
        <ul>
            <li>Súbory môžu byť pred-načítané</li>
            <ul>
                <li>Pred-načítanie zlepšuje výkon stránky</li>
            </ul>
        </ul>
        <pre style={{fontSize: "0.7em"}}>
            <code>
{`<link rel="preload" href="/cesta/styles.css" 
    as="style">
<link rel="preload" href="/cesta/obr.jpg" 
    as="image">    
...
<link rel="stylesheet" href="/cesta/styles.css">
...
<img src="/cesta/obr.jpg" alt="Moj obrazok" />
`}
            </code>
        </pre>
    </>
);

export default ImportManagement;