import styled from "styled-components";

const BigText = styled.div`
    font-size: 1.5em;
    margin-top: .4em;
    text-align: center;
    font-weight: bold;
`

const AboutHTML = () => (
    <>
        <ul>
            <li>
                Hypertextový <b>značkovací</b> jazyk <i>(nie programovací)</i>
            </li>
            <li>
                Základný jazyk webovej stránky
            </li>
            <li>
                Značka = <b>Tag</b>
                <ul>
                    <li>
                        je jazykom určený
                    </li>
                    <li>
                        je ohraničený zátvorkami
                    </li>
                    <li>
                        je ukončený lomítkom
                    </li>
                </ul>
            </li>
            <li>
                Prípona súboru: <b>.html</b> <i>(niekedy .htm)</i>
            </li>
            <BigText>
                {'<tag> ... obsah ... <tag />'}
            </BigText>
            
        </ul>
    </>
);

const HTMLTags = () => (
    <>
    <div className="row">
        <div className="col">
            <ul>
                <li>
                    Tagy delíme
                    <ul>
                        <li>Párové: <b>{"<tag>"}</b> ... <b>{"</tag>"}</b></li>
                        <li>Nepárové: <b>{"<tag />"}</b></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div className="col">
            <ul>
                <li>
                    Tagy majú
                    <ul>
                        <li>Vlastnosti</li>
                        <li>Pravidlá</li>
                        <li>Určenie</li> 
                    </ul>
                </li>
            </ul>
        </div>
        <pre style={{fontSize: "1em"}}>
            <code>
{`<div id="obsah">
    <img 
        src="/url/obrazka.jpg"
    />
</div>
`}
            </code>
        </pre>
    </div>
        
    </>
);

export {AboutHTML, HTMLTags};