import styled from "styled-components";
import LogoImage from "../../images/logo.png";
import {SiAdobelightroomclassic, SiAdobepremiere,SiAdobeillustrator, SiCss3, SiJavascript, SiHtml5} from "react-icons/si";
import {FaLinkedin, FaFacebookSquare, FaInstagram, FaWordpress} from "react-icons/fa";

const Logo = styled.img`
    margin: 10px 0px 0px;
    align-self: center;
    width: 60%;
`

const Description = styled.div`
    font-size: 0.7em;
`

const AboutAcademy = () => (
    <>
        <Logo src={LogoImage} alt="Wooacademy - Photo" />
        <div className="row">
            <div className="col" style={{paddingRight: 30}}>
                <ul>
                    <li>
                        Ponúkame
                        <Description>
                            Kurzy a školenia
                        </Description>
                    </li>
                    <li>
                        K dispozícii pre
                        <Description>
                            Firmy, skupiny a jednotlivcov
                        </Description>
                    </li>
                    <li>
                        Prezentované
                        <Description>
                            Prezenčne a dištančne
                        </Description>
                    </li>
                </ul>
            </div>
            <div className="col left">
                <ul>
                    <li>
                        Online Marketing
                        <Description>
                            SEO, PPC, Email, Social <FaInstagram title="Instagram"/> <FaFacebookSquare title="Facebook"/> <FaLinkedin title="LinkedIn"/>
                        </Description>
                    </li>
                    <li>
                        <b>Tvorba stránok</b>
                        <Description>
                            Programovanie <SiHtml5 title="HTML" /> <SiCss3 title="CSS"/> <SiJavascript title="JavaScript"/> <FaWordpress title="WordPress"/>
                        </Description>
                    </li>
                    <li>
                        Grafika
                        <Description>
                            Fotografovanie, Programy <SiAdobelightroomclassic title="Adobe Lightroom - Fotografie" /> <SiAdobepremiere title="Adobe Premiere - Video" /> <SiAdobeillustrator title="Adobe Illustrator - Vektorová grafika" />
                        </Description>
                    </li>
                    <li>
                        Predajné techniky
                    </li>
                </ul>
            </div>
        </div>
    </>
);

export default AboutAcademy;