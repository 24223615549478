import {useState} from "react";

const PositionsCSS = () => (
    <>
        <ul>
            <li>
                Position (pozícia)
                <ul>
                    <li>
                        Relative (relatívna) - prispôsobuje sa objektom
                    </li>
                    <li>
                        Absolute (absolútna) - prekrýva objekty <b>vzhľadom na najbližšiu rodičovskú relatívnu pozíciu</b>
                    </li>
                    <li>
                        Fixed (fixná) - prekrýva objekty, nemení pozíciu scrollovaním
                    </li>
                </ul>
            </li>
            
        </ul>
        <div style={{position: "relative", marginTop: "1em", background: "silver", height: 150}}>
            <div style={{width: 100, height: 100, position: "absolute", background: "black", left: 0}} />
            <div style={{width: 100, height: 100, position: "absolute", background: "red", left: 50, top: 50}} />
            <div style={{width: 100, height: 100, position: "absolute", background: "red", right: 50, top: 50}} />
            <div style={{width: 100, height: 100, position: "absolute", background: "black", right: 180, top: 0}} />
        </div>
    </>
);

const PositionsCSS2 = () => (
    <>
        <ul>
            <li>
                Nastavenie fixnej a absolútnej pozície
            </li>
        </ul>
        <div className="row">
            <div className="col">
                <pre>
                    <code>
{`.sivy-box {
  position: relative;
}
.cerveny-box {
  position: absolute;
  left: -20px;
  top: 50px;
  right: 20px;
  bottom: 20px;
}
`}               
                    </code>
                </pre>
            </div>
            <div className="col">
                <div style={{position: "relative", marginTop: "1em", background: "silver", height: 350}}>
                    <div style={{width: 200, height: 200, position: "absolute", background: "red", left: -20, top: 50, right: 20, bottom: 20}} />
                </div>
            </div>
        </div>
    </>
);

const PositionsCSS3 = () => {
    
    const [index, setIndex] = useState(1);

    return (
        <>
            <ul>
                <li>
                    Vrstvenie objektov <b>z-index</b>
                </li>
            </ul>
            <div className="row">
                <div className="col">
                    <pre>
                        <code>
{`.cerveny-box {
  position: absolute;
  ...
  z-index: ${index};
}
.modry-box {
  position: absolute;
  ...
  z-index: 2;
}
`}               
                        </code>
                    </pre>
                </div>
                <div className="col">
                    <div style={{position: "relative", marginTop: "1em", background: "silver", height: 350}}>
                        <div onClick={()=>setIndex(3)} style={{width: 200, height: 200, position: "absolute", background: "red", left: -20, top: 50, right: 20, bottom: 20, zIndex: index, cursor: "pointer"}} />
                        <div onClick={()=>setIndex(1)} style={{width: 200, height: 200, position: "absolute", background: "blue", left: 50, top: 150, right: 20, bottom: 20, zIndex: 2, cursor: "pointer"}} />
                    </div>
                </div>
            </div>
        </>
    )
};

const PositionsCSS4 = () => (
    <>
        <ul>
            <li>Float-ovanie objektov
            <ul>
                <li>float: right;</li>
                <li>float: left;</li>
            </ul>
            </li>
            <li>Podobne ako: <b>position: absolute; right: 0px;</b></li>
        </ul>
        <br />
        <div>
            <div style={{backgroundColor: "red", float: "right", width: 150, height: 150}} />
            <div style={{backgroundColor: "blue", float: "left", width: 150, height: 150}} />
        </div>
    </>
)


export {PositionsCSS, PositionsCSS2, PositionsCSS3, PositionsCSS4};