import {useRef} from "react";
import styled from "styled-components";
import RedirectImage from "./assets/redirect.gif";

const AnimatedImage = styled.img`
    position: absolute;
    top: 3em;
    width: 550px;
`

const ScrollDiv = styled.div`
    height: 400px;
    overflow-y: scroll;
    text-align: left;
    font-size: .8em;
`

const HtmlRedirect = () => (
    <>
    <   pre>
            <code>
{`<a 
    href="/kam/chcem/presmerovať"
    target="_blank"
    rel="noreferrer"
>
    Názov linku
</a>
`}
            </code>
        </pre>
        <a 
            href="/kam/chcem/presmerovať"
            target="_blank" 
            rel="noreferrer"
        >
            Názov linku
        </a>
    </>
);

const HtmlRedirect2 = () => (
    <>
        <ul>
            <li>
                <b>href</b> - presmerovanie
               <ul>
                   <li>
                       <b>/o-nas</b>
                   </li>
                   <li>
                       <b>https://</b>www.wooacademy.sk
                   </li>
                   <li>
                        o-nas<b>#zamestnanci</b>
                   </li>
                </ul> 
            </li>
            <li>
                <b>target</b> - cieľ <i>(nepovinné)</i>
               <ul>
                   <li>
                       _blank, _self, 
                   </li>
                </ul> 
            </li>
            <li>
                <b>rel</b> - relácia <i>(nepovinné)</i>
               <ul>
                   <li>
                       next, back, help, license, <br />search, external, author, ...
                   </li>
                </ul> 
            </li>
        </ul>
        <AnimatedImage src={RedirectImage} alt="Redirect" />
    </>
);

const HtmlRedirect3 = () => {
    const contentDiv = useRef(null)
    function executeScroll(e){
        e.preventDefault(); 
        contentDiv.current.scrollIntoView();
    }

    return (
        <>
            <div className="left">Presmerovanie na časť stránky</div>
            <div className="row">
                <div className="col">
                    <pre style={{fontSize: "0.7em"}}>
                        <code>
{`<a href="#cast-stranky">
  Scrollni na obsah
</a>
`}
                        </code>
                    </pre>
                    <pre style={{fontSize: "0.7em"}}>
                        <code>
{`<section 
  id="cast-stranky"
>
  ...
</section>
`}
                        </code>
                    </pre>   
                </div>
                <div className="col">
                    <ScrollDiv>
                        <p>
                            <span style={{color:"blue", cursor: "pointer"}} onClick={executeScroll}>Časť stránky</span>
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae aliquet nec ullamcorper sit amet. Vitae auctor eu augue ut lectus. Non arcu risus quis varius quam. Accumsan in nisl nisi scelerisque. Lectus sit amet est placerat in egestas. Vitae tempus quam pellentesque nec nam aliquam sem et. Amet facilisis magna etiam tempor orci eu lobortis. Nunc aliquet bibendum enim facilisis gravida. Nunc aliquet bibendum enim facilisis gravida neque convallis. Eu sem integer vitae justo eget magna fermentum. Tortor at auctor urna nunc id cursus metus. Id aliquet risus feugiat in ante. Id cursus metus aliquam eleifend mi in nulla. Dolor sit amet consectetur adipiscing elit ut aliquam purus. Eu sem integer vitae justo. Enim eu turpis egestas pretium aenean. Neque egestas congue quisque egestas diam in arcu. Enim nec dui nunc mattis.
                        </p>
                        <p>
                            Ligula ullamcorper malesuada proin libero nunc consequat interdum. Nunc non blandit massa enim nec dui nunc. Iaculis nunc sed augue lacus viverra. In massa tempor nec feugiat nisl pretium fusce. Interdum velit laoreet id donec. Et ultrices neque ornare aenean euismod. Sit amet venenatis urna cursus eget nunc. Cursus in hac habitasse platea dictumst quisque. Lacinia at quis risus sed vulputate odio ut. Enim ut sem viverra aliquet eget sit amet. Aliquet nibh praesent tristique magna. Malesuada fames ac turpis egestas integer. Sit amet cursus sit amet dictum sit amet justo. Mi proin sed libero enim sed faucibus. In tellus integer feugiat scelerisque varius. Risus nec feugiat in fermentum posuere. Vel turpis nunc eget lorem dolor.
                        </p>
                        <div ref={contentDiv}>
                            <h2>** Časť stránky **</h2>
                        </div>
                        <p>
                            Ligula ullamcorper malesuada proin libero nunc consequat interdum. Nunc non blandit massa enim nec dui nunc. Iaculis nunc sed augue lacus viverra. In massa tempor nec feugiat nisl pretium fusce. Interdum velit laoreet id donec. Et ultrices neque ornare aenean euismod. Sit amet venenatis urna cursus eget nunc. Cursus in hac habitasse platea dictumst quisque. Lacinia at quis risus sed vulputate odio ut. Enim ut sem viverra aliquet eget sit amet. Aliquet nibh praesent tristique magna. Malesuada fames ac turpis egestas integer. Sit amet cursus sit amet dictum sit amet justo. Mi proin sed libero enim sed faucibus. In tellus integer feugiat scelerisque varius. Risus nec feugiat in fermentum posuere. Vel turpis nunc eget lorem dolor.
                        </p>
                    </ScrollDiv>
                </div>
            </div>
        
        </>
    );
};

const HtmlRedirect4 = () => (
    <>
        <div className="row">
            <div className="col">
                <pre style={{fontSize: "0.7em"}}>
                    <code>
{`<a 
  href="mailto:email@email.sk"
>
  Odoslať email
</a>
`}
                    </code>
                </pre>
            </div>
            <div className="col" style={{maxWidth: "40%", marginTop: "2em"}}>
                <a 
                    href="mailto:email@email.sk"
                >
                    <p>
                        Odoslať email
                    </p>
                </a>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <pre style={{fontSize: "0.7em"}}>
                    <code>
{`<a 
  href="tel:00421xxxxxx"
>
  Zavolať na číslo
</a>
`}
                    </code>
                </pre>
            </div>
            <div className="col" style={{maxWidth: "40%", marginTop: "2em"}}>
                <a 
                    href="tel:00421xxxxxx"
                >
                    <p>
                        Zavolať na číslo
                    </p>
                </a>
            </div>
        </div>
    </>
)

export {HtmlRedirect, HtmlRedirect2, HtmlRedirect3, HtmlRedirect4};