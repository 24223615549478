import FlexImage from "./assets/flex.png";
import NoIEImage from "./assets/no-ie.png";
import FlexDImage from "./assets/flex-direction.png";
import FlexWImage from "./assets/flex-wrap.png";

const FlexCSS = () => (
    <>
        <ul>
            <li><b>display: flex; </b></li>
            <li>Automatické prispôsobenie prvkov
                <ul>
                    <li>V bloku
                        <div style={{width: 70, height: 70, backgroundColor: "red", color: "white", display: "flex"}}>A</div>
                        <div style={{width: 70, height: 70, backgroundColor: "blue", color: "white", display: "flex"}}>B</div>
                    </li>
                    <li>
                        V inom flex elemente 
                        <div style={{display: "flex"}}>
                            <div style={{width: 70, height: 70, backgroundColor: "red", color: "white", display: "flex"}}>A</div>
                            <div style={{width: 70, height: 70, backgroundColor: "blue", color: "white", display: "flex"}}>B</div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
        <img src={NoIEImage} alt="No IE" style={{width: 200, position: "absolute", bottom: 0, right: "2em"}} />
    </>
)

const FlexCSS2 = () => (
    <>
        <div className="row">
            <div className="col">
                <ul>
                    <li>
                        Zarovnanie flexibilných objektov
                        <ul>
                            <li>align-self: <b>flex-start</b>;</li>
                            <li>align-items: <b>flex-start</b>;</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="col">
                <img src={FlexImage} alt="Flex" style={{width: 300, alignSelf: "center"}} />
            </div>

        </div>
    </>
);

const FlexCSS3 = () => (
    <>
        <ul>
            <li>
                Smer flexibilných objektov
                <ul>
                    <li><b>flex-direction: row;</b></li>
                </ul>
            </li>
        </ul>

        <img src={FlexDImage} alt="Flex Direction" style={{width: 650, alignSelf: "center"}} />
    </>
);

const FlexCSS4 = () => (
    <>
        <ul>
            <li>
                Obalenie flexibilných objektov
                <ul>
                    <li><b>flex-wrap: wrap;</b></li>
                </ul>
            </li>
        </ul>

        <img src={FlexWImage} alt="Flex Wrap" style={{width: "90%", alignSelf: "center"}} />
        <ul>
            <li><b>flex-flow: SMER USPORIADANIE;</b>
            <ul><li>flex-flow: row wrap;</li></ul>
            </li>
        </ul>
    </>
);

export {FlexCSS, FlexCSS2, FlexCSS3, FlexCSS4};