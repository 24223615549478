import {useEffect,useRef} from "react";
import Chart from "chart.js";

const OPTIONS = {
    title:{
      display: false,
    },
    legend:{
        display:false,
    },
    layout:{
      padding:{
        left:50,
        right:0,
        bottom:0,
        top:0
      }
    },
};

const Contents = () => {
    const chart = useRef(null);

    useEffect(() => {

        const DATA = {
            datasets: [{
                data: [70, 20, 240 - 70 - 20],
                backgroundColor:[
                    '#12a532',
                    '#CF181F',
                    '#dadada',
                ],
            }],
            labels: [
                'Úvod, jazyk HTML',
                'Prestávka',
                'Zostávajúci čas',
            ],
        };

        new Chart(chart.current.getContext('2d'), {
            type: 'doughnut',
            data: DATA,
            options: OPTIONS,
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col">
                    <ul>
                        <li>
                            Úvod do sveta webu
                            <ul>
                                <li>Kde je uložený?</li>
                                <li>Ako je rozdelený?</li>
                                <li>Ako ho môžem vytvoriť?</li>
                            </ul>
                        </li>
                        <li>
                            Jazyk HTML
                            <ul>
                                <li>Značky</li>
                                <li>Multimédiá</li>
                                <li>Štruktúra</li>
                            </ul>
                        </li>
                        <li>
                            Moja prvá stránka!
                        </li>
                        <li>
                            <b>Prestávka</b>
                        </li>
                    </ul>
                </div>
                <div className="col" style={{maxWidth: "40%"}}>
                    <canvas ref={chart} width="300" height="300"></canvas>
                </div>
            </div>
        </>
    )
};

const Contents2 = () => {
    const chart = useRef(null);

    useEffect(() => {

        const DATA = {
            datasets: [{
                data: [70, 20, 70, 10, 240 - 140 - 30],
                backgroundColor:[
                    '#12a532',
                    '#CF181F',
                    '#12a532',
                    '#CF181F',
                    '#dadada',
                ],
            }],
            labels: [
                'Úvod, jazyk HTML',
                'Prestávka',
                'Štýlovanie CSS',
                'Prestávka',
                'Zostávajúci čas',
            ],
        };

        new Chart(chart.current.getContext('2d'), {
            type: 'doughnut',
            data: DATA,
            options: OPTIONS,
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col">
                    <ul>
                        <li>
                            Jazyk CSS
                            <ul>
                                <li>Selektory</li>
                                <li>Štýlovanie obsahu</li>
                                <li>Pokročilé štýlovanie</li>
                                <li>Responzivita</li>
                                <li>Animácie</li>
                            </ul>
                        </li>
                        <li>
                            Štýlovanie webovej stránky
                        </li>
                        <li>
                            <b>Prestávka</b>
                        </li>
                    </ul>
                </div>

                <div className="col" style={{maxWidth: "40%"}}>
                    <canvas ref={chart} width="300" height="300"></canvas>
                </div>
            </div>
        </>
    )
};

const Contents3 = () => {
    const chart = useRef(null);

    useEffect(() => {

        const DATA = {
            datasets: [{
                data: [70, 20, 70, 10, 240 - 140 - 30],
                backgroundColor:[
                    '#12a532',
                    '#CF181F',
                    '#12a532',
                    '#CF181F',
                    '#12a532',
                ],
            }],
            labels: [
                'Úvod, jazyk HTML',
                'Prestávka',
                'Štýlovanie CSS',
                'Prestávka',
                'Optimalizácia a testovanie',
            ],
        };

        new Chart(chart.current.getContext('2d'), {
            type: 'doughnut',
            data: DATA,
            options: OPTIONS,
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col">
                    <ul>
                        <li>
                            Optimalizácia stránky
                            <ul>
                                <li>Meta údaje</li>
                                <li>Sociálne siete</li>
                                <li>Optimalizácia obrázkov a CSS</li>
                                <li>Úprava stránky</li>
                                <li>Nasadenie stránky online</li>
                                <li>Testovanie</li>
                            </ul>
                        </li>
                        <li>
                            Čo ďalej po kurze?
                            <ul>
                                <li>Individuálna konzultácia</li>
                                <li>Riešenie špecifických problémov</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="col" style={{maxWidth: "40%"}}>
                    <canvas ref={chart} width="300" height="300"></canvas>
                </div>
            </div>
        </>
    )
};

export {Contents, Contents2, Contents3};