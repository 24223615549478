const UsingCSS = () => (
    <>
        <pre style={{fontSize: "1.8em"}}>
            <code>
{`SELEKTOR { 
    KĽÚČ: HODNOTA ;
}
`}
            </code>
        </pre>
    </>
);

const UsingCSS2 = () => (
    <>
        <pre style={{fontSize: "1.8em"}}>
            <code>
{`img { 
    width: 100px ;
}
`}
            </code>
        </pre>
        <div className="left">Selektor: <b>img</b> (obrázok)</div>
        <div className="left">Kľuč: <b>width</b> (šírka)</div>
        <div className="left">Hodnota: <b>100px</b> (konkrétna veľkosť)</div>
    </>
);

export {UsingCSS, UsingCSS2}