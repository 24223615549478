import { AiFillWindows, AiFillApple } from "react-icons/ai";
import { DiLinux } from "react-icons/di";
import FZ1 from "./images/fz1.png";
import FZ2 from "./images/fz2.png";
import FZ3 from "./images/fz3.png";
import LoginImage from "./images/login.png";
import LoginImage2 from "./images/login2.png";
import TerminalImage from "./images/terminal.png";

const WebsiteAccess = () => (
    <>
        <div className="row">
            <div className="col">
                <ul>
                    <li>
                        Administratívne rozhranie
                    </li>
                    <li>
                        FTP klient
                    </li>
                    <li>
                        Terminál / Shell
                    </li>
                </ul>
            </div>
            <div className="col" style={{maxWidth: "45%"}}>
                <img src={LoginImage} alt="Login" />
            </div>
        </div>
    </>
);

const WebsiteAccess2 = () => (
    <>
        <ul>
            <li>
                Administratívne rozhranie
                <ul>
                    <li>www.<b>admin.</b>stranka.sk, www.<b>login.</b>stranka.sk</li>
                    <li>www.stranka.sk<b>/admin</b>, www.stranka.sk<b>/login</b></li>
                    <li>www.stranka.sk<b>/wp-admin</b>, www.stranka.sk<b>/wp-login</b></li>
                    <li>vzdialený prístup (napr. Shoptet)</li>
                </ul>
            </li>
        </ul>
        <img src={LoginImage2} alt="Login" style={{marginTop: 50}} />
    </>
)

const WebsiteAccess3 = () => (
    <>
        <div className="row">
            <div className="col" style={{maxWidth: "40%"}}>
                <ul>
                    <li>
                        FTP klient
                        <ul>
                            <li>
                                <a href="https://filezilla-project.org/download.php?type=client" rel="noreferrer" target="_blank">FileZilla <AiFillWindows /> <AiFillApple /> <DiLinux /></a>
                            </li>
                            <li>
                                <a href="https://winscp.net/eng/download.php" rel="noreferrer" target="_blank">WinSCP <AiFillWindows /></a>
                            </li>
                            <li>
                                <a href="https://www.smartftp.com/en-us/download" rel="noreferrer" target="_blank">SmartFTP <AiFillWindows /></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="col">
                <div className="r-stack">
                    <img className="fragment fade-out" alt="FileZilla 1" data-fragment-index="0" src={FZ1} width="500" />
                    <img className="fragment current-visible" alt="FileZilla 2" data-fragment-index="0" src={FZ2} width="550"/>
                    <img className="fragment" src={FZ3} alt="FileZilla 3" width="550"/>
                </div>
            </div>
        </div>
    </>
)

const WebsiteAccess4 = () => (
    <>
        <ul>
            <li>
                Prostredníctvom terminálu
                <ul>
                    <li>Webový Shell (napr. Websupport)</li>
                    <li>Natívny Terminál / Shell</li>
                    <li>SSH Klient, napr.{" "} 
                        <a href="https://www.putty.org/" rel="noreferrer" target="_blank">Putty <AiFillWindows /></a>,{" "} 
                        <a href="https://apps.apple.com/us/app/termius-ssh-client/id1176074088?mt=12" rel="noreferrer" target="_blank">Termius <AiFillApple /></a>,{" "} 
                    </li>
                </ul>
            </li>
        </ul>
        <img src={TerminalImage} alt="terminal" width="480" style={{margin: 0}} />
    </>
)

export {WebsiteAccess, WebsiteAccess2, WebsiteAccess3, WebsiteAccess4};