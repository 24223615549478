const Meta = () => (
    <>
        <ul>
            <li>Doplňujúce informácie</li>
            <li>Nachádzajú sa v tagu <b>{"<head>"}</b></li>
        </ul>
        <table style={{width: "100%", fontSize: "0.7em"}}>
            <thead>
                <tr>
                    <th>Meta</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {"<title>Názov </title>"}
                    </td>
                    <td>
                        Názov dokumentu
                    </td>
                </tr>
                <tr>
                    <td>
                        {'<meta name="description" content="Popis stránky">'}
                    </td>
                    <td>
                        Popis stránky
                    </td>
                </tr>
                <tr>
                    <td>
                        {'<meta name="copyright" content="Wooacademy">'}
                    </td>
                    <td>
                        Copyright
                    </td>
                </tr>
                <tr>
                    <td>
                        {'<meta name="language" content="SK">'}
                    </td>
                    <td>
                        Jazyk
                    </td>
                </tr>
                <tr>
                    <td style={{fontSize: "0.7em"}}>
                        {'<meta name=viewport content="width=device-width, initial-scale=1">'}
                    </td>
                    <td>
                        Škálovanie dizajnu
                    </td>
                </tr>
                <tr>
                    <td>
                        {'<meta charset="utf-8">'}
                    </td>
                    <td>
                        Kódovanie textu
                    </td>
                </tr>
            </tbody>
        </table>
    </>
);

export default Meta;