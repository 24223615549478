const Coureses = () => (
    <>
        <iframe 
            src="https://wooacademy.sk/kurzy-a-skolenia/"  
            title="Wooacademy - Kurzy a školenia" 
            style={{width: "100%", height: 540}}
        />
    </>
);

export default Coureses;