const HTMLPositionCSS = () => (
    <>
        <ul>
            <li>V spodnej časti tagu <b>{"<head>"}</b></li>
            <li>Ohraničené párovým tagom <b>{"<style> ... </style>"}</b></li>
        </ul>
        <pre style={{height: 300}}>
            <code style={{height: "100%"}}>
{`<html>
  <head>
    ...
    <style>
      !! Vaše CSS
    </style>
  <head>
  <body>
    ...
  </body>
  </html>
`}
            </code>
        </pre>
    </>
);

export default HTMLPositionCSS;