const UsabilityToolsHTML = () => (
    <>  

        <table style={{width: "100%", fontSize: "0.7em"}}>
            <thead>
                <tr>
                    <th>Názov</th>
                    <th>Popis</th>
                    <th>Typ</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>WooRank</td>
                    <td>SEO analýza a audit stránky</td>
                    <td>doplnok Chrome</td>
                    <td><a href="https://cutt.ly/DkbS3Ps" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>Wappalyzer</td>
                    <td>Prieskumník technológií</td>
                    <td>doplnok Chrome</td>
                    <td><a href="https://cutt.ly/zkbDRFf" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>CanIUse.com</td>
                    <td>Podpora prehliadačov</td>
                    <td>online nástroj</td>
                    <td><a href="https://caniuse.com/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>Bitly</td>
                    <td>Skracovač URL adries</td>
                    <td>online nástroj</td>
                    <td><a href="https://bitly.com/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>ILoveImg</td>
                    <td>Orezávanie obrázkov</td>
                    <td>online nástroj</td>
                    <td><a href="https://www.iloveimg.com/crop-image" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>Convertio</td>
                    <td>Konverzia obrázkov</td>
                    <td>online nástroj</td>
                    <td><a href="https://convertio.co/png-webp/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>ShrinkMe</td>
                    <td>Kompresia obrázkov (aj webp)</td>
                    <td>online nástroj</td>
                    <td><a href="https://shrinkme.app/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>Flaticon</td>
                    <td>Bezplatné ikony</td>
                    <td>online nástroj</td>
                    <td><a href="https://www.flaticon.com/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
                <tr>
                    <td>Pixabay</td>
                    <td>Bezplatné fotky a videá</td>
                    <td>online nástroj</td>
                    <td><a href="https://pixabay.com/sk/" target="_blank" rel="noreferrer">otvoriť</a></td>
                </tr>
            </tbody>
        </table>
    </>
)

export default UsabilityToolsHTML;