const DisplayCSS = () => (
    <>
    <div className="row">
        <div className="col">
            <ul>
                <li>Blokové usporiadanie
                    <ul>
                        <li><b>display: block</b>;
                            <div style={{width: 70, height: 70, backgroundColor: "red", color: "white", textAlign: "center"}}>A</div>
                            <div style={{width: 70, height: 70, backgroundColor: "blue", color: "white", textAlign: "center"}}>B</div>

                        </li>
                        <li><b>display: inline-block;</b> <br />
                            <div style={{width: 70, height: 70, backgroundColor: "red", display: "inline-block", color: "white", textAlign: "center"}}>A</div>
                            <div style={{width: 70, height: 70, backgroundColor: "blue", display: "inline-block", color: "white", textAlign: "center"}}>B</div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div className="col">
            <ul>
                <li>Obsahové usporiadanie
                    <ul>
                        <li><b>display: contents;</b>
                            <br />
                            <div style={{width: 70, height: 70, backgroundColor: "red", display: "contents"}}>A</div>
                            <div style={{width: 70, height: 70, backgroundColor: "blue", display: "contents"}}>B</div>
                        </li>
                    </ul>
                </li>
                <li>Odstránenie z obsahu
                    <ul>
                        <li><b>display: none;</b></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
        
    </>
);

export default DisplayCSS;