import RevealHighlight from 'reveal.js/plugin/highlight/highlight.js';

const PresentationConfig = {
    plugins: [ RevealHighlight ],
    slideNumber: "c/t",
    center: false,
    history: true,
    margin: 0.06,
}

export default PresentationConfig;