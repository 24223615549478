import styled from "styled-components";
import FinalImage from "./assets/fin.gif";

const Title = styled.div`
    margin-top: 2.2em;
    position: absolute;
    z-index: 2;
`

const Image = styled.div`
    position: absolute;
    z-index: 1;
    top: 6em;
    right: 0em;
    width: 565px;
`

const Thanks = (props) => (
    <>
        <Title>
            <h2 style={{fontSize: "2.4em"}}>{props.title}</h2>
            <div className="left">
                <i>Čas na konzultáciu</i>
            </div>
        </Title>
        <Image>
            <img src={FinalImage} alt="Final GIF" style={{width: "100%"}} />
        </Image>
    </>
);

export default Thanks;