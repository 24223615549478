import GoogleImage from "./assets/googleScreen.png";

const SEO = () => (
    <>
        <img src={GoogleImage} alt="Google screen" />
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`<head>
  ...
  <title>Kurzy a školenia z online marketingu</title>
  <meta name="description" content="Nauč sa ...">	
</head>
`}               
            </code>
        </pre>  
    </>
);

const SEO2 = () => (
    <>
        <ul>
            <li><b>robots.txt</b> - Čo (ne)má Google prehliadať
            <ul>
                <li>
                    Musí byť <b>www.domena.sk/robots.txt</b>
                </li>
            </ul>
            
            <pre>
                
{`Sitemap: https:// ...
User-agent: *
Disallow: /admin
Disallow: /api
`}               
                    
                </pre>  

            </li>
            <li><b>sitemap.xml</b> - Aké podstránky mám</li>
            <ul>
                <li>Môžete vygenerovať na <a href="https://www.xml-sitemaps.com/" rel="noreferrer" target="_blank">xml-sitemaps.com</a>
                </li>
                <li>
                    Zvyčajne uložená <b>www.domena.sk/sitemap.xml</b>
                </li>
            </ul>
        </ul>
    </>
);

export {SEO, SEO2};