const ExCssWebsite = () => (
    <>
        <ul>
            <li>Upravte stránku podľa dizajnu
              <ul>
                <li>Desktopové rozhranie - <a href="/files/moja-stranka-2.png" target="_blank" rel="noreferrer">Zobraziť</a></li>
                <li>Mobilné rozhranie - <a href="/files/moja-stranka-2-m.png" target="_blank" rel="noreferrer">Zobraziť</a></li>
                <li>Verzia pre tlač - <a href="/files/moja-stranka-2-tlac.pdf" target="_blank" rel="noreferrer">Zobraziť</a></li>
              </ul>
            </li>
            <li>Vložte efekt obrázka v obsahu po prejdení myšou</li>
            <li>Použite tieňované písmo v hlavnom názve</li>
            <li>Použite ľubovoľné pozadie v záhlaví</li>
            <li>Použite ľubovoľný font z Google Fonts</li>
        </ul>
    </>
);

export default ExCssWebsite;