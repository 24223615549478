import styled from "styled-components";
import WebsiteImage from "./assets/website.gif";

const Title = styled.div`
    margin-top: 2.2em;
    position: absolute;
    z-index: 2;
`

const Image = styled.div`
    position: absolute;
    z-index: 1;
    top: 5.5em;
    right: -1em;
    width: 650px;
`

const Website = (props) => (
    <>
        <Title>
            <h2 style={{fontSize: "2.4em"}}>{props.title}</h2>
            <div className="left">
                <i>Čo si? Kde si? Kedy si?</i>
            </div>
        </Title>
        
        <Image>
            <img src={WebsiteImage} alt="Website" />
        </Image>
    </>
);

export default Website;