import IndexImage from "./assets/indexFile.png";

const IndexFile = () => (
    <>
        <div className="row">
            <div className="col">
                <div className="left">Označenie hlavného súboru.</div>
                <ul>
                    <li>V priečinku len jeden</li>
                    <li>Má rôzne prípony</li>
                    <li>Prehliadačom ignorovaný</li>
                    <ul>
                        <li>Štandardne platí <br />pre index.html a index.php</li>
                    </ul>
                </ul>
                <div className="left" style={{marginTop: ".5em"}}>
                    <a href="https://wooacademy.sk" target="_blank" rel="noreferrer">wooacademy.sk</a> <br />
                    <a href="https://wooacademy.sk/index.php" target="_blank" rel="noreferrer">wooacademy.sk/<b>index.php</b></a>
                </div>
            </div>
            <div className="col" style={{maxWidth: "40%"}}>
                <img src={IndexImage} alt="Index file"/>
            </div>
        </div>
        
    </>
);

export default IndexFile;