import Image from "./assets/ba-img.jpg";

const CSS3 = () => (
    <>
        <div className="left">
            Gradient - <a href="https://html-css-js.com/css/generator/gradient/" target="_blank" rel="noreferrer">Generátor gradientov</a>
        </div>
        <pre style={{fontSize: ".65em"}}>
            <code>
{`background: linear-gradient(70deg, blue, red);
background: linear-gradient(70deg, red, yellow, blue, orange);
background: radial-gradient(blue, red);
background: repeating-linear-gradient(-45deg, red 0px, red 5px, blue 5px, blue 10px);
`}               
            </code>
        </pre>  
        <div style={{display: "inline-flex", width: "100%"}}>
            <div style={{width: 150, height: 150, background: "linear-gradient(70deg, blue, red)", marginRight: 20}} />
            <div style={{width: 150, height: 150, background: "linear-gradient(70deg, red, yellow, blue, orange)", marginRight: 20}} />
            <div style={{width: 150, height: 150, background: "radial-gradient(blue, red)", marginRight: 20}} />
            <div style={{width: 150, height: 150, background: "repeating-linear-gradient(-45deg, red 0px, red 5px, blue 5px, blue 10px)"}} />
        </div>
        
    </>
);

const CSS32 = () => (
    <>
        <div className="left">
            Orámovanie a tieňovanie - <a href="https://html-css-js.com/css/generator/box-shadow/" target="_blank" rel="noreferrer">Generátor tieňa</a>
        </div>
        <pre style={{fontSize: ".65em"}}>
            <code>
{`border: 10px solid black;
border: 10px solid dotted; border-radius: 50%;
box-shadow: 5px 10px black:
box-shadow: 0px 0px 15px 5px black;
text-shadow: 20px 5px 12px black;
`}               
            </code>
        </pre>  
        <div style={{display: "inline-flex", width: "100%"}}>
            <div style={{width: 150, height: 150, background: "red", marginRight: 20, border: "10px solid black"}} />
            <div style={{width: 150, height: 150, background: "red", marginRight: 20, border: "10px dotted black", borderRadius: "50%"}} />
            <div style={{width: 150, height: 150, background: "red", marginRight: 40, boxShadow: "5px 10px black"}} />
            <div style={{width: 150, height: 150, background: "red", boxShadow: "0px 0px 15px 5px black"}} />
            <div style={{fontSize: "3em", marginLeft: "0.5em", textShadow: "20px 5px 12px black"}}>P</div>
        </div>
    </>
);

const CSS33 = () => (
    <>
        <div className="left">
            Manipulácia s obrázkami
        </div>
        <pre style={{fontSize: ".65em", marginBottom: 0}}>
            <code>
{`filter: blur(15px); filter: contrast(200%);
filter: grayscale(100%); filter: hue-rotate(90deg);
filter: drop-shadow(16px 16px 20px red) invert(75%);
`}               
            </code>
        </pre>  
        <div style={{display: "inline-flex", width: "100%"}}>
            <img src={Image} alt="BA" style={{width: 150, marginRight: 30, filter: "blur(15px)"}} />
            <img src={Image} alt="BA" style={{width: 150, marginRight: 30, filter: "contrast(200%)"}} />
            <img src={Image} alt="BA" style={{width: 150, marginRight: 30, filter: "grayscale(100%)"}} />
            <img src={Image} alt="BA" style={{width: 150, marginRight: 30, filter: "hue-rotate(90deg)"}} />
            <img src={Image} alt="BA" style={{width: 150, marginRight: 30, filter: "drop-shadow(16px 16px 20px red) invert(75%)"}} />
        </div>
        
    </>
);

export {CSS3, CSS32, CSS33}