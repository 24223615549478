import styled from "styled-components";
import Photo from "./assets/my-photo.jpg";

const Avatar = styled.img`
    margin: 10px 0px 0px;
    border-radius: 50%;
    width: 70%;
    align-self: center;
`

const Description = styled.div`
    font-size: 0.7em;
`

const AboutMe = () => (
    <>
        <div className="row">
            <div className="col" style={{paddingRight: 30}}>
                <Avatar src={Photo} alt="Patrik Hudák - Photo" />
            </div>
            <div className="col left">
                <div style={{fontSize: "1.2em"}}>
                    <b>Patrik</b> Hudák
                </div>
                <div style={{fontSize: "0.8em"}}>
                    <i>Fullstack web developer</i>
                </div>
                <ul style={{marginTop: 40}}>
                    <li>
                        Prezentačné stránky
                        <Description>
                            <b>HTML, CSS, JavaScript</b>
                        </Description>
                    </li>
                    <li>
                        Online systémy
                        <Description>
                            PHP, Python, NodeJS
                        </Description>
                    </li>
                    <li>
                        Kampane a reklama
                        <Description>
                            Teasing, Moduly, Social correction
                        </Description>
                    </li>
                </ul>
            </div>
        </div>
    </>
);

export default AboutMe;