import styled from "styled-components";
import ImageHTML from "./assets/boost.gif";

const Title = styled.div`
    margin-top: 2.2em;
    position: absolute;
    z-index: 2;
`

const Image = styled.div`
    position: absolute;
    z-index: 1;
    top: 3em;
    right: -4em;
    width: 700px;
`

const Optimalization = (props) => (
    <>
        <Title>
            <h2 style={{fontSize: "2.4em"}}>{props.title}</h2>
            <div className="left">
                <i>SEO, SOCIAL, BOOST!</i>
            </div>
        </Title>
        <Image>
            <img src={ImageHTML} alt="Boost Rocket" />
        </Image>
    </>
);

export default Optimalization;