import MinifyImage from "./assets/css-min.png";

const SeparateCSS = () => (
    <>
        <ul>
            <li>
                Štýl CSS <b>môže byť izolovaný</b> v súbore [názov]<b>.css</b>
            </li>
            <li>
                Súbor CSS <b>môže byť pripojený</b> ako externý zdroj
            </li>
        </ul>
        <pre style={{fontSize: "1em"}}>
            <code>
{`<link 
    rel="stylesheet" 
    href="/cesta/styles.css"
>
`}
            </code>
        </pre>
    </>
);

const SeparateCSS2 = () => (
    <>
        <ul>
            <li>
                Izolovaný súbor CSS môže byť zmenšený
                <ul>
                    <li>Napr. prostredníctvom <a href="https://cssminifier.com" target="_blank" rel="noreferrer">cssminifier.com</a></li>
                    <li>Pomenovaný ako zmenšený <b>[nazov].min.css</b></li>
                </ul>
            </li>
        </ul>
        <img src={MinifyImage} alt="cssminifier.com" />
    </>
);

export {SeparateCSS, SeparateCSS2};