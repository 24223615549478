import BraillerImage from "./assets/braille-writer.jpg";

const DisabledPeople = () => (
    <>
        <div className="row">
            <div className="col">
                <ul>
                    <li>
                        Štruktúra stránky
                        <ul>
                            <li><a href="https://wave.webaim.org/" rel="noreferrer" target="_blank">wave.webaim.org</a></li>
                        </ul>
                    </li>
                    <li>Alt atribúty obrázkov</li>
                    <li>
                        Dostatočný kontrast
                        <ul>
                            <li><a href="http://colorsafe.co/" rel="noreferrer" target="_blank">colorsafe.co</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="col">
                <img src={BraillerImage} alt="Čítačka braillového písma" />
            </div>
            <ul>
                <li>
                    Výstižné prelinky
                    <pre style={{fontSize: "0.7em"}}>
                        <code>
{`<a href="#">Dokument GDPR</a>
<a href="#" aria-label="Dokument GDPR">TU</a>
`}
                        </code>
                    </pre>
                </li>
            </ul>
        </div>
    </>
);

export default DisabledPeople;