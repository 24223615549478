import styled from "styled-components";
import WebsiteImage from "./assets/build-web.gif";

const Title = styled.div`
    margin-top: 2.2em;
    position: absolute;
    z-index: 2;
`

const Image = styled.div`
    position: absolute;
    z-index: 1;
    top: 3.5em;
    right: 0em;
    width: 575px;
`

const Editors = (props) => (
    <>
        <Title>
            <h2 style={{fontSize: "2.4em"}}>{props.title}</h2>
            <div className="left">
                <i>Ako? Prečo? Kde?</i>
            </div>
        </Title>
        
        <Image>
            <img src={WebsiteImage} alt="Website Build" />
        </Image>
    </>
);

export default Editors;